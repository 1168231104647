@import "../variables";
@import "../mixins";

body section.section--donate-banner {
  background-color: $malala;
  color: $white;
  position: relative;

  h2 {
    color: $peach;
  }

  .accent {
    position: absolute;
  }

  .accent-gray {
    top: -20px;
    right: 50%;
  }

  .accent-peach {
    top: 55%;
    right: -15px;
  }

  @media (min-width: $media-lg) {
    .donate__banner__content {
      max-width: 500px;
    }
  }

  @media (max-width: $media-md-max) {
    padding-top: 85px !important;
    padding-bottom: 85px !important;

    .accent-gray {
      right: 25px;
    }

    .donate__banner__content {
      padding-right: 25px;
    }

    .button--large {
      display: block;
    }
  }
}
