@import "variables";
@import "mixins";

/**
 * Card groups. Aligns the height of a row of cards.
 */
.card-group,
.card-group > div,
.card-group > div > div {
  display: flex;
  align-content: stretch;
  align-items: stretch;
}

.card-group > div > div {
  width: 100%;
}

.cards {
  .card__column {
    margin-top: $gutter-width * 0.5;
    margin-bottom: $gutter-width * 0.5;
  }
}

// Special, very targeted thirds override for Partners Supporter column
@media (min-width: $media-lg) {
  .page--partners {
    .section--advocate-and-supporters {
      .card__column.column-full.column-lg-third.flex:nth-child(2) {
        flex-basis: calc(66.66% - 24px);

        .card__content .imgs-fluid {
          columns: 2;
        }
      }
    }
  }
}

.card__body {
  justify-content: center;
}

@media (max-width: $media-sm-max) {
  .card.card--has-link.card--hover-popout .color-bar,
  .card.card--has-link.card--hover-popout .pattern-bar,
  .card.card--has-link .card__hover {
    display: none !important;
  }
}

@media (max-width: $media-lg-max) {
  .card {
    font-size: $font-size;
    line-height: 1.27;
  }
}

@media (max-width: $media-md-max) {
  .card-group,
  .card-group > div,
  .card-group > div > div {
    flex-wrap: wrap;
  }
}

@media (max-width: $media-sm-max) {
  .card-group,
  .card-group > div,
  .card-group > div > div {
    display: block;
  }

  body .card {
    margin: 0 auto;

    &--large {
      .card__hover,
      .card__body {
        padding-left: $container-padding-mobile;
        padding-right: $container-padding-mobile;
      }

      .card__image__wrapper--stretch {
        margin-left: -$container-padding-mobile;
        margin-right: -$container-padding-mobile;

        width: calc(100% + #{$container-padding-mobile * 2});
      }
    }
  }
}

a.card,
a.card:hover,
a.card:active,
a.card:visited {
  text-decoration: none;
  color: inherit;
}

.card {
  display: flex;
  position: relative;
  box-sizing: border-box;
  min-height: var(--hover-height, 0px);

  h3 {
    font-weight: $font-weight-semibold;
    line-height: 1.2;
    font-size: 16px;
    color: $dark-gray;
    letter-spacing: 1px;
  }

  .h4,
  .h5,
  h5,
  h4 {
    @extend .caption !optional;
    font-size: $font-size-secondary;
  }

  @include text {
    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &--warm-gray,
  &--warm-gray .card__hover {
    background-color: $warm-gray;
  }

  &--white,
  &--white .card__hover {
    background-color: $white;
  }

  .card__heading,
  .card__title,
  .card__hover .card__content,
  .card__footer,
  .card__content {
    box-sizing: border-box;
    padding: $spacing-xs * 0.5 0;
  }

  .card__image--full {
    max-height: var(--image-height, 315px);
    object-fit: contain;
  }

  .card__image__wrapper + .card__footer {
    padding-top: 0px;
  }

  &--small {
    .card__hover,
    .card__body {
      padding: $spacing-xxs $spacing-xs;
    }

    .card__image__wrapper--stretch {
      margin-top: -$spacing-xxs;
      margin-left: -$spacing-xs;
      margin-right: -$spacing-xs;

      width: calc(100% + #{$spacing-xs * 2});
    }

    .card__footer {
      margin-bottom: -$spacing-xxs;
    }
  }

  &--regular {
    .card__hover,
    .card__body {
      padding: $spacing-xs;
    }

    .card__image__wrapper--stretch {
      margin-top: -$spacing-xs;
      margin-left: -$spacing-xs;
      margin-right: -$spacing-xs;
      margin-bottom: $spacing-xs * 0.5;

      width: calc(100% + #{$spacing-xs * 2});
    }

    .card__footer {
      margin-bottom: -$spacing-xs;
    }
  }

  &--large {
    .card__hover,
    .card__body {
      padding: $spacing-l $spacing-m;
    }

    .card__image__wrapper--stretch {
      margin-top: -$spacing-l;
      margin-left: -$spacing-m;
      margin-right: -$spacing-m;
      margin-bottom: $spacing-xs * 0.5;

      width: calc(100% + #{$spacing-m * 2});
    }

    .card__footer {
      margin-bottom: -$spacing-l;
    }
  }

  &.card--hover-popout {
    .color-bar,
    .pattern-bar {
      position: absolute;
      bottom: var(--hover-bottom, 0);
      left: 0;
      right: 0;
      transform: translateY(100%);
      visibility: hidden;
      width: 90%;
    }

    &:focus-within,
    &:hover {
      .color-bar,
      .pattern-bar {
        visibility: visible;
      }
    }

    .card__hover {
      top: unset !important;
      width: 90%;
      bottom: var(--hover-bottom, 0);
    }

    .card__footer {
      .card__icon {
        padding: 0px;
      }
    }
  }

  .card-title,
  .card-content,
  .card-footer {
    padding: $spacing-xs;

    p:last-child {
      margin-bottom: 0px;
    }
  }

  .card-content {
    padding-bottom: 60px;
  }

  .card__content {
    font-weight: $font-weight-light;
    line-height: 1.45;

    h1,
    .h1 {
      color: $dark-gray;
    }

    &:first-child {
      padding-top: 0px;
    }

    &:last-child {
      padding-bottom: 0px;
    }
  }

  .card-footer {
    margin-top: -60px;
  }

  .card-footer,
  .card__footer {
    text-decoration: none;
    color: inherit;
    padding-top: 28px;
    padding-bottom: 14px;

    .button--link,
    .button {
      margin-top: 14px;
      margin-bottom: 14px;

      &:not(:last-child) {
        margin-right: 14px;
      }
    }
  }

  .card__footer {
    .card__heading {
      &:first-child {
        margin-top: 0px;
        padding-top: 0px;
      }

      &:last-child {
        margin-bottom: 0px;
        padding-bottom: 0px;
      }
    }

    .card__icon {
      position: absolute;
      top: -4px;
      right: 0;
    }

    .card__icon-wrapper {
      position: relative;
      padding-right: 30px;
    }
  }

  .color-bar,
  .pattern-bar {
    flex-grow: 0 !important;
  }

  .card__body + .color-bar,
  .card__body + .pattern-bar {
    margin-top: 10px;
  }

  &-hover {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 10px;
    left: 0px;
    background-color: white;
    padding: 20px;
    pointer-events: none;
    visibility: hidden;

    .card-hover-content {
      flex-grow: 1;
      width: 100%;
    }

    .card-hover-footer {
      width: 100%;
      margin-top: -60px;

      .button {
        margin-right: 7px;
      }
    }
  }

  .card__hover {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 18px;
    left: 0px;
    visibility: hidden;
    pointer-events: none;
    overflow: hidden;
    box-sizing: border-box;

    .card__footer {
      position: relative;
    }

    .card-hover-footer {
      .button {
        margin-right: 7px;
      }
    }
  }

  &.card--bar-bar .card__hover {
    bottom: 10px;
  }

  &.card--bar-tall .card__hover {
    bottom: 45px;
  }

  &:focus-within,
  &:hover {
    .card__hover,
    .card-hover {
      pointer-events: initial;
      visibility: visible;
      flex-direction: column;
      display: flex;
    }
  }

  &-column {
    flex-direction: column;

    .card-content {
      flex-grow: 1;
    }
  }

  &-row {
    flex-direction: column;

    .card-content {
      flex-grow: 1;
    }
  }

  .image {
    width: 100%;
  }
}

.carousel .card {
  max-width: 100%;
  height: 100%;
}

.outside {
  .card:hover .card-hover {
    display: none;
  }
}
