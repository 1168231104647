/****************************
 * Width Helpers
 *****************************
 *
 * Provides the following helper classes:
 *
 * .width-{amount}
 * .width-{breakpoint}-{amount}
 *
 * Breakpoint: xl, lg, md, sm, xl (see _variables.scss)
 * Amount: 25, 50, 75, 100
 *
 * Examples:
 * .width-75: sets the width to 75%
 * .width-sm-50: sets the width to 50% for small devices and higher.
 */

@mixin makeWidths($breakpoint: null) {
  @if $breakpoint {
    $breakpoint: "-#{$breakpoint}";
  }

  .width#{$breakpoint}-25 {
    width: 25%;
    max-width: 25%;
  }
  .width#{$breakpoint}-33 {
    width: 33.3%;
    max-width: 33.3%;
  }
  .width#{$breakpoint}-40 {
    width: 40%;
    max-width: 40%;
  }
  .width#{$breakpoint}-50 {
    width: 50%;
    max-width: 50%;
  }
  .width#{$breakpoint}-60 {
    width: 60%;
    max-width: 60%;
  }
  .width#{$breakpoint}-66 {
    width: 66.6%;
    max-width: 66.6%;
  }
  .width#{$breakpoint}-75 {
    width: 75%;
    max-width: 75%;
  }

  .width#{$breakpoint}-100 {
    width: 100%;
    max-width: 100%;
  }

  .width#{$breakpoint}-full {
    width: 100%;
  }

  .width#{$breakpoint}-brand {
    width: var(--brand-width, 33.3%);
  }

  .width#{$breakpoint}-menu {
    width: var(--menu-width, 66.6%);
  }

  .width#{$breakpoint}-nav {
    width: var(--nav-width, 66.6%);
  }
}

@include makeWidths;

//Make the responsive breakpoints
@each $breakpoint, $value in $breakpoints {
  //The default spacer size
  @media (min-width: $value) {
    @include makeWidths($breakpoint);
  }
}