.section--join-form-light-blue {
  &.bg-sky {
    background-color: $cobalt-5;
  }

  @media (max-width: $media-sm-max) {
    input[type=submit] {
      align-self: stretch;
      margin: 0;
      padding: 20px;
    }

    form {
      margin-top: 75px;
    }

    input[type=email] {
      margin-right: 10px;
    }
  }
}