
.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-position-top {
  object-position: top center !important;
}

.object-position-center {
  object-position: center center !important;
}