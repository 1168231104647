@import "../variables";
@import "../mixins";

.photo-stack {
  .photo-stack__stack {
    position: relative;
    padding-top: 50%;
    margin: 0 auto;
  }

  .photo-stack__caption {
    position: relative;
    z-index: 100;
    text-align: left;
    width: 60%;
    transform: rotate(-1deg)
      translateY(var(--photo-stack-caption-translate-y, 0));
    margin-left: auto;
    margin-right: 10%;
    margin-top: calc(var(--photo-stack-caption-offset, 0));

    p {
      font-family: $font-family-serif;
      font-weight: 600;
      letter-spacing: normal;
      color: $cool-gray;
    }
  }

  .photo-stack__caption-inner {
    @extend .highlight !optional;
    font-family: $font-family-serif;
    font-weight: 300;
    font-style: normal;
    line-height: 1.3;
    position: relative;
    font-size: 36px;
    -webkit-font-smoothing: antialiased;
    color: $cool-gray;

    &:after {
      content: "";
      height: 1.5em;
      width: 0.75em;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      background-color: $seafoam;
    }
  }

  .photo-stack__link {
    white-space: nowrap;
    font-size: $font-size;
    font-family: $font-family-sans-serif;
    margin-left: $spacer * 0.5;
    text-decoration: none !important;
    position: relative;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    text-transform: uppercase;
    color: $cool-gray;
    display: inline-block;
    transform: translateY(-0.5em);
    font-weight: 800;

    &:hover {
      text-decoration: underline !important;
    }

    i {
      margin-left: 5px;
    }
  }

  .photo-stack__photo {
    z-index: var(--photo-stack-z, 1);
    @include position-fit;
  }

  .photo-stack__image {
    @include position-fit;
    cursor: pointer;
  }

  .photo-stack__image picture {
    @include position-fit;
    display: flex;
  }

  .photo-stack__image img {
    @extend .img-fluid !optional;
    width: 90% !important;
    max-height: 90% !important;
    object-fit: contain;
  }

  .photo-stack__photo--position-top-right img {
    align-self: flex-start;
    margin-left: auto;
  }

  .photo-stack__photo--position-bottom-left img {
    align-self: flex-end;
    margin-right: auto;
  }

  .photo-stack__photo--position-top-left img {
    align-self: flex-start;
    margin-right: auto;
    margin-left: 5%;
  }

  .photo-stack__photo--position-center img {
    align-self: center;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: $media-sm-max) {
    .photo-stack__stack {
      padding-top: 70%;
    }

    .photo-stack__image img {
      object-fit: cover;
    }

    .photo-stack__caption {
      --highlight-padding: 0.7em;
      width: 70%;
      margin-top: calc(var(--photo-stack-caption-offset, 0) + 20px);
    }

    .photo-stack__link {
      font-size: 6.3px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
    }

    .photo-stack__link img {
      width: 8px;
      height: auto;
    }

    .photo-stack__caption-inner {
      font-size: 18px;

      &:after {
        width: 1.2em;
        height: 2em;
      }
    }
  }
}

@media (max-width: $media-sm-max) {
  .container.photo-stack__container {
    padding-left: 0px;
    padding-right: 0px;
  }
}
