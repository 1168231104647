@import "../variables";
@import "../mixins";

.flipbook {
  input[type="email"] {
    min-width: 60%;
  }
}

.container .flipbook.container {
  width: auto;
}

.section--content-white {
  @include text {
    color: white;
  }
}
@media (max-width: $media-md-max + 80px) {
  .flipbook input[type="email"] {
    min-width: 50%;
  }
}
