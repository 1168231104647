section.section--countries {
  .accent {
    position: absolute;
  }

  .accent--0 {
    width: 138px;
    right: -30px;
    bottom: 120px;
  }

  .accent--1 {
    width: 58px;
    right: 100px;
    bottom: 30px;
  }

  @media (max-width: $media-md-max) {
    .accent--0 {
      width: 85px;
      bottom: -85px;
    }

    .accent--1 {
      bottom: -100px;
    }
  }
}