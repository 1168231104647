@mixin set-page-gutter-attribute($selector, $attribute) {
  #{$selector} {
    #{$attribute}: $page-gutter-width;
  }

  @media (max-width: $media-xxl-max) {
    #{$selector} {
      #{$attribute}: $page-gutter-width-xxl;
    }
  }

  @media (max-width: $media-xl-max) {
    #{$selector} {
      #{$attribute}: $page-gutter-width-xl;
    }
  }

  @media (max-width: $media-lg-max) {
    #{$selector} {
      #{$attribute}: $page-gutter-width-lg;
    }
  }

  @media (max-width: $media-md-max) {
    #{$selector} {
      #{$attribute}: $page-gutter-width-md;
    }

    .container-fluid,
    .container {
      #{$selector} {
        #{$attribute}: 0;
      }
    }
  }

  @media (max-width: $media-sm-max) {
    #{$selector} {
      #{$attribute}: $page-gutter-width-sm;
    }
  }
}

@mixin set-container-gutter-attribute($selector, $attribute) {
  #{$selector} {
    #{$attribute}: $container-padding;
  }

  @media (max-width: $media-md-max) {
    #{$selector} {
      #{$attribute}: $container-padding-md;
    }
  }

  @media (max-width: $media-sm-max) {
    #{$selector} {
      #{$attribute}: $container-padding-sm;
    }
  }
}

@mixin set-gutter-attribute($selector, $attribute) {
  #{$selector} {
    #{$attribute}: $gutter-width;
  }
}