.border-box {
  box-sizing: border-box !important;
}

.content-box {
  box-sizing: content-box !important;
}

.padding-box {
  box-sizing: padding-box !important;
}