@import "../variables";
@import "../mixins";

.pill {
  white-space: nowrap;
  background-color: #ccd3e9;
  color: $cobalt;
  border-radius: 1000px;
  padding: 10px 7px;
  font-size: $font-size-tiny;
  margin-right: 5px;
  line-height: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: $font-weight-medium;
  cursor: pointer;
  letter-spacing: 1px;

  &:hover {
    background-color: $cobalt-2;
    color: $warm-gray;
    text-decoration: none;
  }
}

.pill--warm-gray {
  background-color: $warm-gray;
}
