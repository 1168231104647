.transition  {
  transition: all .5s ease-in-out;
}

.animation--rotate {
  -webkit-animation: rotate 4s infinite ease;
}

@-webkit-keyframes rotate {
  0%   {-webkit-transform: rotate(0deg)}
  25%  {-webkit-transform: rotate(180deg)}
  50%  {-webkit-transform: rotate(180deg)}
  75%  {-webkit-transform: rotate(360deg)}
  100% {-webkit-transform: rotate(360deg)}
}
