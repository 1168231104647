@import "../variables";
@import "../mixins";

body .updates-card {
  bottom: var(--lower-by, 0) !important;
  margin-top: var(--lower-by, 0) !important;

  .card__body {
    padding: $spacing-xl;
  }

  .card__heading {
    padding-top: 0px;
  }

  .card__content {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .updates-card__update {
    font-size: 18px;
    margin-bottom: $spacing-s;
    margin-top: $spacing-xs;

    &:last-child {
      margin-bottom: 0px;
    }

    &:first-child:last-child {
      .updates-card__update__title {
        font-weight: $font-weight-light;
        font-size: 30px;
        color: $malala;
        line-height: 1.25;
        letter-spacing: 0.24px;
        margin-bottom: $spacing-m !important;
        position: relative;
        top: -7px;
      }
    }
  }

  .updates-card__update__title {
    font-weight: $font-weight-bold;
  }

  @media (max-width: $media-xl-max) {
    .updates-card__update:first-child:last-child .updates-card__update__title {
      font-size: $font-size-callout;
    }

    .updates-card__update .updates-card__update__title {
      margin-right: -50px;
    }
  }

  @media (max-width: 1200px) {
    .updates-card__update .updates-card__update__title {
      margin-right: 0;
    }
  }

  @media (max-width: $media-md-max) {
    margin-bottom: 45px !important;
  }
}

.updates__content {
  max-width: 50%;
}
