@import "../variables";
@import "../mixins";

.alert.alert--covid-19-initiative {
  background-color: transparent;
  padding: 0 !important;
  color: $emerald;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.18px;
  font-size: $font-size-medium;

  .alert__icon {
    display: none;
  }

  .alert__title,
  .alert__label {
    padding-top: $spacing-xxs;
    padding-bottom: $spacing-xxs;
  }

  .alert__title {
    padding-right: $covid-spacing;
    border-right: $covid-border;
    text-transform: uppercase;
  }

  .alert__title-icon {
    margin-left: $spacing-xs;
    margin-right: $spacing-xxs;
  }
}

@media (min-width: $media-lg) {
  .alert.alert--covid-19-initiative {
    .alert__content {
      border-bottom: $covid-border;
      padding-left: $spacing-xs;
      padding-right: $spacing-xxs;
    }

    .alert__label {
      padding-left: $covid-spacing;
    }

    &:hover {
      .alert__content {
        background-color: rgba(255, 255, 255, 0.9);
      }
    }

    .alert__content {
      background-color: rgba(255, 255, 255, 0.75);
    }
  }
}

@media (max-width: $media-xxl-max) {
  .alert.alert--covid-19-initiative {
    font-size: $font-size-small;

    .hero__overlay__wrapper h3 {
      font-size: $font-size-extra-large;
    }
  }
}

@media (max-width: $media-md-max) {
  .alert.alert--covid-19-initiative {
    background-color: rgba(255, 255, 255, 0.75);

    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }

    border-bottom: $covid-border;
    text-align: center;
    font-size: $font-size-secondary;

    .alert__label {
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      line-height: 2.29;
      letter-spacing: 0.35px;
    }
  }
}

@media (max-width: 470px) {
  .alert.alert--covid-19-initiative {
    font-size: 2.6vw;
  }
}
