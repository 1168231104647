@import "../variables";
@import "../mixins";

.video-flipbook {
  .video-flipbook__video__wrapper {
    @media (min-width: $media-lg) {
      margin-right: -$container-padding;
    }

    @media (max-width: $media-md-max) {
      margin-right: -$container-padding-tablet;
    }
  }

  .video-flipbook__video,
  .video-flipbook__video__wrapper {
    @media (max-width: $media-sm-max) {
      margin-right: 0;
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
