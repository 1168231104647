@import "../variables";
@import "../mixins";

.pdf-carousel {
  overflow: hidden;

  @media (min-width: $media-lg) {
    .swiper {
      margin-left: 12vw;
    }
  }

  .swiper-wrapper {
    overflow: visible;
  }

  canvas {
    width: 100%;
    height: auto;
  }

  .swiper-slide {
    border: solid 1px #c4c4c4;

    @media (min-width: $media-lg) {
      width: 65vh;
      max-width: 80vw;
    }

    @media (max-width: $media-md-max) {
      width: 50vh;
      max-width: 80vw;
    }
  }

  .pdf-carousel__toolbar {
    display: flex;
    margin-top: $gutter-width;
    justify-content: space-between;

    .swiper-pagination {
      display: block;
      position: unset;
      top: unset;
      bottom: unset;
      right: unset;
      left: unset;
      text-align: left;
      margin-right: 50px;
    }

    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      margin-right: 12px;
    }

    .button--link {
      margin-right: 25px;
      color: $cobalt;
    }

    .pdf-carousel__page-count {
      letter-spacing: 1.3px;
      color: #979797;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: bold;
    }

    .count__current {
      color: $cobalt;
    }
  }

  .pdf-carousel__pagination-label {
    color: $cobalt !important;
    opacity: 1 !important;
  }

  .pdf-carousel__link-icon {
    margin-left: 3px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 80px;
    height: 110px;
    background-color: transparent;
    background-position: top left;
    transition: all 500ms ease;
  }

  .swiper-button-prev {
    background-image: url("~images/ui/ui-thin-arrow-left.svg");

    &:hover {
      transform: translateX(-20px);
    }
  }

  .swiper-button-next {
    background-image: url("~images/ui/ui-thin-arrow-right.svg");
    &:hover {
      transform: translateX(20px);
    }
  }

  @media (max-width: $media-md-max) {
    .container {
      padding-left: 20px;
      padding-right: 20px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}
