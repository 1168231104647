@import "variables";
@import "mixins";

// Styles related to text formatting.

@mixin set-font-sizes($multiplier: 1) {
  h1,
  .h1 {
    font-size: $font-size-h1 * $multiplier;
  }

  h1.lead,
  .h1.lead,
  .headings--lead h1,
  .headings--lead .h1 {
    font-size: ($font-size-h1 + 10px) * $multiplier;
  }

  h2,
  .h2 {
    font-size: $font-size-h2 * $multiplier;
  }

  h2.lead,
  .h2.lead,
  .headings--lead h2,
  .headings--lead .h2 {
    font-size: $font-size-h2 * $multiplier;
  }

  h3,
  .h3 {
    font-size: $font-size-h3 * $multiplier;
  }

  h3.lead,
  .h3.lead,
  .headings--lead h3,
  .headings--lead .h3 {
    font-size: ($font-size-h3 + 10px) * $multiplier;
  }

  h4,
  .h4 {
    font-size: $font-size-h4 * $multiplier;

    &.lead {
      font-size: ($font-size-h4 + 10px) * $multiplier;
    }
  }

  h4.lead,
  .h4.lead,
  .headings--lead h4,
  .headings--lead .h4 {
    font-size: ($font-size-h4 + 10px) * $multiplier;
  }

  h5,
  .h5 {
    font-size: $font-size-h5 * $multiplier;
  }

  h5.lead,
  .h5.lead,
  .headings--lead h5,
  .headings--lead .h5 {
    font-size: ($font-size-h5 + 10px) * $multiplier;
  }

  h6,
  .h6 {
    font-size: $font-size-base * $multiplier;
  }

  h6.lead,
  .h6.lead,
  .headings--lead h6,
  .headings--lead .h6 {
    font-size: ($font-size-h5 + 10px) * $multiplier;
  }

  h1.tag,
  .hero__below .hero__heading > h1,
  main > .container > h1,
  main > h1 {
    font-size: $font-size * $multiplier;
  }

  .lead {
    font-size: $font-size-jumbo * $multiplier;
  }

  .text-callout {
    font-size: $font-size-callout * $multiplier;
  }

  .text-display {
    font-size: $font-size-display * $multiplier;
  }

  .text-mega-sans,
  .text-jumbo {
    font-size: $font-size-jumbo * $multiplier;
  }

  .ql-size-huge,
  .text-large {
    font-size: $font-size-large * $multiplier;
  }

  .ql-size-large,
  .text-medium {
    font-size: $font-size-medium * $multiplier;
  }

  .text-base {
    font-size: $font-size-base * $multiplier;
  }

  .ql-size-small,
  .text-small {
    font-size: $font-size-small * $multiplier;
    line-height: 1.5;
  }

  .text-tiny {
    font-size: $font-size-tiny * $multiplier;
  }

  .blockquote,
  blockquote {
    font-size: $font-size-callout * $multiplier;
  }

  .caption,
  caption {
    font-size: 11px * $multiplier;
  }

  blockquote > i:last-child,
  cite {
    font-size: $font-size-base * $multiplier;
  }
}

@include set-font-sizes();

body {
  font-size: $font-size-base;
  font-family: $font-family-sans-serif;
  line-height: $line-height-base;
  color: $zia;
  letter-spacing: $letter-spacing-base;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: $gutter-width;
}

a {
  color: $peace;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

h1,
.h1 {
  font-family: $font-family-serif;
  font-weight: bold;
  line-height: 1.25em;
  color: $malala;
  margin-bottom: $gutter-width;
}

h1.tag,
h1.subheader,
main > .container > h1,
main > h1 {
  margin: $spacing-m 0;
  font-family: $font-family-sans-serif;
  line-height: 1.5;
  color: $black;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;

  &:last-child {
    margin-bottom: 0px;
  }
}

h2,
.h2 {
  font-family: $font-family-serif;
  color: #ce4b1c;
  line-height: 1.25;
  margin-bottom: $spacing-base;
}

h2.subheader {
  color: $dark-gray;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  line-height: 1.5;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h3,
.h3 {
  font-family: $font-family-sans-serif;
  color: $malala;
  line-height: 1.3;
  letter-spacing: 0.22px;
  font-weight: $font-weight-light;
  margin-bottom: $spacing-tiny;
}

h4,
.h4 {
  font-family: $font-family-sans-serif;
  letter-spacing: 0.25px;
  font-weight: bold;
  line-height: 22px;
  color: $dark-gray;
  margin-bottom: $spacing-tiny;
}

h5,
.h5 {
  font-family: $font-family-sans-serif;
  letter-spacing: 0.22px;
  font-weight: bold;
  line-height: 18px;
  color: $dark-gray;
  margin-bottom: $spacing-tiny;
}

h6,
.h6 {
  font-family: $font-family-sans-serif;
  font-weight: bold;
  letter-spacing: 0.22px;
  line-height: 18px;
  color: $dark-gray;
  margin-bottom: $spacing-tiny;
}

p > br {
  &:first-child:last-child {
    display: none;
  }
}

.lead {
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-light;
  line-height: 1.2;
  letter-spacing: normal;
  color: $navy;
}

.headings--lead {
  @include headings() {
    @extend .lead !optional;
  }
}

.text-callout {
  line-height: normal;
  letter-spacing: 0.15px;
}

.text-display {
  letter-spacing: 0;
  line-height: 56px;
}

.text-mega-sans,
.text-jumbo {
  letter-spacing: 0;
  line-height: 60px;
}

.text-large {
  letter-spacing: 0.35px;
  line-height: 24px;
}

.text-medium {
  letter-spacing: 0.28px;
  line-height: 1.5;
}

.text-base {
  letter-spacing: $letter-spacing-base;
  line-height: $line-height-base;
}

.text-small {
  letter-spacing: 0.25px;
  line-height: 1.5;
}

.text-tiny {
  letter-spacing: 0.25px;
  line-height: 1.5;
}

.text-uppercase {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.text-transform-none {
  text-transform: none !important;
}

.text-spaced {
  letter-spacing: 0.1em;
}

.font-family-serif {
  font-family: $font-family-serif;
}

.label {
  font-family: $font-family-sans-serif;
  letter-spacing: 1.3px;
  color: $peace;
  text-transform: uppercase;
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  cursor: pointer;

  @media (max-width: $media-sm-max) {
    color: $cool-gray;
    font-size: $font-size-small;
  }
}

// Pattern hero.

.pattern-hero {
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }
}

@each $size, $multiplier in $text-display-sizes {
  .text-display-#{$size} {
    @include set-font-sizes($multiplier);
  }
}

@media (max-width: $media-xxl-max) {
  @include set-font-sizes(0.9);
}

@media (max-width: $media-sm-max) {
  .text-mega-sans,
  .text-jumbo {
    font-size: $font-size-display;
  }

  .text-large {
    font-size: $font-size-large;
    letter-spacing: 0.36px;
  }

  .lead {
    font-size: 27px;
    line-height: 40px;
    letter-spacing: 0.2px;
  }
}

.line-height-0 {
  line-height: 0;
}

.line-height-1 {
  line-height: 1;
}
