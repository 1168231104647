@import "../variables";
@import "../mixins";

.color-bar {
  background-position: 0;
  height: 18px;

  &--tall {
    height: 45px;
  }

  &--bar {
    height: 10px;
  }

  @each $color, $value in $colors {
    &--#{"" + $color} {
      background-color: $value;
    }
  }
}
