.section--strategic-plan {
  .accents {
    z-index: 1;
  }
  @media (min-width: $media-lg) {
    .accent--0 {
      display: none;
    }
  }

  @media (max-width: $media-md-max) {
    padding-top: 65px;
    padding-bottom: 50px;

    .accent--0 {
      width: 104px;
      position: absolute;
      top: 0px;
      right: 25px;
      transform: translateY(-50%);
    }

    .image-flipbook__image_container {
      display: none;
    }
  }
}