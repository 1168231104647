.position-fixed,
.fixed {
  position: fixed;
}

.position-relative,
.relative {
  position: relative;
}

.position-absolute,
.absolute {
  position: absolute;
}

.position-sticky,
.sticky {
  position: sticky;
  top: 0;
}

.position-center,
.absolute-center {
  @include position-center();
}

.position-top,
.absolute-top {
  @include position-top();
}

.position-top-left,
.absolute-top-left {
  @include position-top-left();
}

.position-bottom,
.absolute-bottom {
  @include position-bottom();
}

.position-left,
.absolute-left {
  @include position-left();
}

.position-right,
.absolute-right {
  @include position-right();
}

.cover,
.position-fit,
.fit {
  @include position-fit();
}