@import "variables";
@import "mixins";

input[type="submit"],
.button {
  @include transition;
  display: inline-block;
  border: $btn-border-width solid $btn-outline-color;
  border-radius: $btn-border-radius;
  padding: $btn-padding-y $btn-padding-x;
  width: fit-content;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
  font-family: $font-family-sans-serif;
  font-size: $btn-font-size;
  color: $malala;
  text-transform: uppercase;
  font-weight: $btn-font-weight;
  letter-spacing: $btn-letter-spacing;
  line-height: 1;
  cursor: pointer;
  background: transparent;

  &:hover {
    background-color: $peace-l1;
    color: $white !important;
    text-decoration: none;
    border-color: $peace-l1;
  }
}

.button {
  &--white,
  &-white {
    color: $peace;
    border-color: $white;
    background-color: $white;
  }

  &--malala,
  &-malala {
    color: $white;
    background-color: $peace;
    border-color: $peace;

    &:hover {
      background-color: $malala;
    }
  }

  &--secondary,
  &-secondary {
    color: $white;
    background-color: $peace-l1;
    border-color: $peace-l1;

    &:hover {
      background-color: $peace-l2;
    }
  }

  &--light {
    color: $peace;
    background-color: $peace-l2;
    border-color: $peace-l2;

    &:hover {
      background-color: $peace;
      color: $peace-l2;
    }
  }

  &--link,
  &-link {
    font-family: $font-family-sans-serif;
    letter-spacing: 1.3px;
    color: $peace;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: $btn-font-weight;
    cursor: pointer;
    text-underline-offset: 0.21rem;

    @media (max-width: $media-sm-max) {
      font-size: $font-size-small;
    }

    &:hover {
      opacity: 0.75;
    }
  }

  &--twitter,
  &-twitter {
    color: $peace;
    position: relative;
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }

    &:before {
      position: relative;
      top: 2px;
      content: "\f099";
      font-family: "Font Awesome 5 Brands";
      margin-right: 5px;
      font-size: 1.3em;
      line-height: 1em;
    }
  }

  &--medium {
    padding: $spacing-s $spacing-l;
    font-size: $font-size-base;
  }

  &--large {
    padding: $spacing-s $spacing-l;
    font-size: $font-size-extra-large;
  }

  &--wide {
    padding: $spacing-s $spacing-l;
  }

  &--icon-hover {
    .fa {
      display: none;
    }

    &:hover {
      .fa {
        display: inline-block;
      }
    }
  }

  @media (max-width: $media-sm-max) {
    &--large {
      padding: $spacing-xs $spacing-xs;
      font-size: $font-size-medium;
    }
  }
}

a:hover .button--icon-hover:only-child .fa {
  display: inline-block;
}

.buttons {
  .button {
    margin-bottom: $spacing-xxs;
  }

  .button:not(:last-child) {
    margin-right: $spacing-xxs;
  }
}
