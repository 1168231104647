@import "../variables";
@import "../mixins";

#give-lively-widget .gl-widget {
  color: $black;
}

#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .btn,
#give-lively-widget .gl-widget__btn,
#give-lively-widget
  .gl-widget
  #give-in-honor-container
  button.popup-card__link {
  border-radius: 4px !important;
  border: solid 1px $cobalt !important;
  font-size: $font-size-tiny !important;
  font-weight: $font-weight-bold !important;
  color: $cobalt !important;
  text-transform: uppercase !important;
  letter-spacing: 1.36px !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
  margin-bottom: 4px !important;
  height: 44px !important;
  cursor: pointer !important;
}

#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .btn:first-of-type,
#give-lively-widget .gl-widget__btn:first-of-type {
  margin-left: 0 !important;
}

#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .btn:last-of-type,
#give-lively-widget .gl-widget__btn:last-of-type {
  margin-right: 0 !important;
}

#give-lively-widget .gl-widget__btn--primary {
  background-color: white !important;
  color: $cobalt !important;
}

#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .btn-outline-secondary,
#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .btn-outline-primary,
#give-lively-widget .gl-widget__btn--outline-primary {
  background-color: white !important;
  color: $cobalt !important;
  border-width: 3px !important;
}

#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .btn-primary,
#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .btn:hover,
#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .btn.active,
#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .btn-primary.active,
#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .btn-primary:hover,
#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .btn-outline-primary.active,
#give-lively-widget .gl-widget__btn--outline-primary:hover,
#give-lively-widget .gl-widget__btn--outline-primary.gl-widget--selected-btn {
  background-color: $cobalt !important;
  color: $white !important;
}

#give-lively-widget .gl-widget__btn--outline-primary.gl-widget--selected-btn,
#give-lively-widget .gl-widget__btn--outline-primary:focus,
#give-lively-widget .gl-widget__btn--outline-primary:hover {
  padding: 1px !important;
}

#give-lively-widget .gl-widget__input-group {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: solid 2px $cobalt !important;
  border-radius: 0px !important;
  margin-top: 4px !important;
}

#give-lively-widget .gl-widget__donation-input-field {
  font-family: $font-family-sans-serif !important;
  text-align: right !important;
  font-size: 28px !important;
  letter-spacing: 3.17px !important;
  color: $black !important;
  height: 44px !important;
  padding: 0 40px 0 40px !important;
  background-color: inherit !important;
}

#give-lively-widget .gl-widget__donation-input-field:focus {
  outline: none;
}

#give-lively-widget .gl-widget__currency-symbol {
  left: 0 !important;
  font-size: $font-size-extra-extra-large !important;
  line-height: normal !important;
  letter-spacing: 2.72px !important;
}

#give-lively-widget .gl-widget__currency {
  background-color: transparent !important;
  color: $cobalt !important;
  font-weight: $font-weight-bold !important;
  height: auto !important;
  line-height: 1em !important;
  top: auto !important;
  bottom: 9px !important;
}

#give-lively-widget .gl-widget__currency-abbr {
  font-size: 15px !important;
  letter-spacing: 1px !important;
  right: 0 !important;
}

#give-lively-widget
  .gl-widget
  #give-in-honor-container
  button.popup-card__link {
  height: 48px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

#give-lively-widget
  .gl-widget
  #give-in-honor-container
  button.popup-card__link:hover {
  background-color: $cobalt-2 !important;
  color: white !important;
}

#give-lively-widget
  .gl-widget
  #give-in-honor-container
  button.popup-card__link
  svg {
  display: none !important;
}

#give-lively-widget .gl-widget #give-in-honor-container div.popup-card__modal {
  font-family: $font-family-sans-serif !important;
}

#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .modal-title {
  font-size: 18px !important;
  margin: 0.4em 0 0em 0 !important;
  text-align: center !important;
  text-transform: none !important;
  width: 100% !important;
}

#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  input {
  background-color: #fff !important;
  border: 1px solid $cobalt !important;
  padding: 0.5em !important;
  font-size: $font-size-medium !important;
  margin: 0.5em 0 !important;
}

#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .modal-footer,
#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .modal-header {
  padding: 0.5em 1.5rem !important;
}

#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .mt-4 {
  margin-bottom: 0px !important;
}

#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .modal-header
  .close {
  position: relative !important;
  right: -8px !important;
}

#give-lively-widget
  .gl-widget
  #give-in-honor-container
  div.popup-card__modal
  .popup-card__action {
  border-width: 1px !important;
}

@media (min-width: $media-xxl) {
  #give-lively-widget .gl-widget__payment-request-button-container > * {
    max-width: 50% !important;
    float: left !important;
  }

  #give-lively-widget .gl-widget__other-payment-method-link {
    padding: 16px 0 3px 0;
    margin: 0 16px;
    border-bottom: solid 3px $cobalt;
    text-decoration: none !important;
    &:hover {
      border-bottom-color: $cobalt-2 !important;
    }
  }
}

@media (max-width: $media-md-max) {
  #give-lively-widget .gl-widget .gl-widget__frequency-selection-container {
    flex-direction: column !important;
  }

  #give-lively-widget .gl-widget .gl-widget__frequency-buttons {
    flex-basis: 44px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  #give-lively-widget
    .gl-widget
    .gl-widget__suggested-amounts-container
    .gl-widget--row {
    justify-content: space-between !important;
  }

  #give-lively-widget .gl-widget .gl-widget__suggested-amount-button {
    flex-grow: 0 !important;
    flex-basis: calc(50% - 2.5px) !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-size: $font-size-medium !important;
  }

  #give-lively-widget .gl-widget .gl-widget__btn {
    margin-bottom: 7px !important;
  }
}
