@import "variables";
@import "mixins";

footer {
  border-top: 1px solid $luiza-d1;
  font-size: $font-size-base;
  line-height: 1.45;
  letter-spacing: 0.12px;
  overflow: hidden;

  h4 {
    font-size: $font-size-small;
    color: $dark-gray;
    font-weight: $font-weight-semibold;
    line-height: 1.5;
    letter-spacing: 0.25px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .email-subscribe {
    input[type="email"] {
      width: 185px;
    }
  }
}

body.embed footer {
  border-top: 0px;
}

.footer__background {
  background-position: right -21px;
  background-size: 1300px;
}

.footer__main {
  padding: 50px 0;
}

.footer__logo {
  width: 275px;
}

.footer__columns {
  font-size: $font-size-base;
  gap: $spacing-base;
}

.footer__columns a {
  display: block;
}

.footer__column:last-child {
  margin-right: 0;
}

.footer__accent {
  position: absolute;
  right: -100px;
  top: 50%;
  transform: translateY(-100%);
  pointer-events: none;
}

.footer__social-media-icon {
  margin-right: 5px;

  img {
    width: 20px;
  }
}

.footer__mission {
  max-width: 330px;
}

.footer__terms-and-conditions {
  background-color: $malala;
  padding: 15px 0;
  font-weight: $font-weight-semibold;
  color: $zia-l2;
  font-size: $font-size-secondary;

  p {
    margin-top: 0;
    margin-bottom: 0;
    color: $peace-l2;
  }

  a {
    margin: 0 10px;
    color: $white;
  }

  a:first-child {
    margin-left: 0;
  }
}

@media (max-width: 1440px) {
  .footer__accent {
    right: -30px;
  }
}

@media (max-width: $media-xxl-max) {
  footer {
    .email-subscribe {
      input[type="email"] {
        width: 120px;
      }
    }
  }
}

@media (max-width: $media-lg-max) {
  footer {
    line-height: 1.56;
  }
}

@media (max-width: $media-md-max) {
  .footer__terms-and-conditions {
    font-size: $font-size-tiny;

    color: $zia-l2;

    a {
      color: $white;
    }
  }

  .footer__terms-and-conditions__nav {
    margin-bottom: $spacing-xxs;
  }

  .footer__columns {
    font-size: $font-size-secondary;

    h4 {
      display: none;
    }

    a {
      padding: $spacing-tiny 0;
    }
  }

  .footer__main {
    padding: $spacing-m 0;
  }

  .footer__mission {
    max-width: initial;
  }

  .footer__logo {
    width: 212px;
  }

  .footer__social-media-icon {
    font-size: 28px;
    margin-right: $spacing-xs;
  }

  .footer__background {
    background-position: right 291px;
  }

  .footer__accent {
    top: 60%;
    right: $spacing-base;
  }

  footer {
    .email-subscribe {
      h4 {
        margin-bottom: 4px;
        font-size: $font-size-base;
        font-weight: $font-weight-regular;
        text-transform: none;
      }
    }
  }
}

@media (max-width: $media-sm-max) {
  .footer__mission {
    max-width: 330px;
  }

  .footer__logo {
    display: none;
  }

  .footer__terms-and-conditions__nav span:first-child a {
    margin-left: 0px !important;
  }
}

@media (max-width: $media-xxs-max) {
  .footer__terms-and-conditions__nav a {
    font-size: 10px;
  }
}
