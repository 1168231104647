.sticky-quotes {
  padding: 0;

  .swiper-container {
    @media(max-width: $media-md) {
      overflow: visible;
    }
  }

  .swiper-container .swiper-slide {
    opacity: 1;
  }

  .swiper-pagination {
    @media (max-width: $media-md-max) {
      bottom: -35px;
    }
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: white;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: $peach;
  }

  .sticky-quotes__carousel {
    height: calc(100% - 53px);
  }

  .sticky-quotes__sticky {
    position: sticky;
    top: var(--header-height, 100px);
    height: calc(var(--vh, 1vh) * 100 - var(--header-height, 100px));
    max-height: 1040px;
    overflow: hidden;
  }

  .sticky-quotes__step {
    height: calc(var(--vh, 1vh) * 50);
    max-height: 520px;
  }

  .sticky-quotes__slide {
    display: flex;
    flex-direction: column;

    &:nth-child(3n+1) {
      --qoute-accent-color: #{$teal};

      .sticky-quotes__accent {
        background-image: url('~images/accents/accent-9-teal.svg');
      }
    }

    &:nth-child(3n+2) {
      --qoute-accent-color: #{$peach};

      .sticky-quotes__accent {
        background-image: url('~images/accents/accent-18-solid-peach.svg');

        top: -1.4rem;

        @media (min-width: $media-md) {
          top: -1.3rem;
          right: -1.3rem;
        }
      }
    }

    &:nth-child(3n+3) {
      --qoute-accent-color: #{$seafoam};

      .sticky-quotes__accent {
        background-image: url('~images/accents/accent-10-seafoam-emerald.svg');

        top: -1.3rem;

        @media (min-width: $media-md) {
          right: -1.3rem;
        }
      }
    }
  }

  .sticky-quotes__container {
    width: 100%;
    flex-grow: 1;
    position: relative;
    z-index: 1;
  }

  .sticky-quotes__heading {
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: $cobalt;

    @media (min-width: $media-md) {
      background-color: transparent;
    }

    h2 {
      color: white;
      font-weight: $font-weight-light;
      font-family: $font-family-sans-serif;
      background-color: $cobalt;
      padding: .75rem 0 1rem;
      margin-top: 0px;
      font-size: 32px;
      line-height: 1.13;

      @media (min-width: $media-md) {
        padding: .75rem 1rem 1rem;
        text-align: center;
      }

      @media (min-width: $media-lg) {
        font-size: 42px;
      }
    }
  }

  .sticky-quotes__footer {
    background-color: $cobalt;

    @media (min-width: $media-md) {
      background-color: transparent;
    }

    .container {
      background-color: $cobalt;
      height: 53px;
    }
  }

  .sticky-quotes__content {
    width: 292px;
    position: absolute;
    bottom: 4rem;
    right: 0;
    padding: 25px 16px 1rem 20px;

    @media (min-width: $media-md) {
      min-width: 320px;
      max-width: 450px;
    }

    @media (min-width: $media-md) and (max-width: $media-md-max) {
      bottom: 0;
      padding-left: 1.5rem;
    }

    @media (min-width: $media-lg) {
      right: 18px;
      padding: 40px 24px 2rem 32px;
    }

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-color: $white;
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      z-index: -2;
      background-color: var(--qoute-accent-color, $cobalt);
      top: 13px;
      right: 18px;
      left: -11px;
      bottom: -.5rem;

      @media (min-width: $media-md) {
        left: -1rem;
        bottom: -1rem;
      }
    }
  }

  .sticky-quotes__quote_wrap {
    position: relative;
    z-index: 1;
    padding-right: 1rem;

    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .sticky-quotes__quote {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.13px;
    font-weight: $font-weight-light;
    margin-bottom: 1.5rem;
    display: block;

    @media (min-width: $media-md) {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.2px;
      text-indent: -8px;
    }

    @media (min-width: $media-lg) and (max-height: 899px) {
      font-size: 3.5vh;
      line-height: 4.2vh;
    }

    @media (min-width: $media-md) and (max-height: $media-md-max) {
      font-size: 3vh;
      line-height: 3.4vh;
    }
  }

  .sticky-quotes__accent {
    display: block;
    content: '';
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: -1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;

    @media (min-width: $media-md) {
      top: -2rem;
      right: -2rem;
      width: 4rem;
      height: 4rem;
    }
  }

  .sticky-quotes__cite {
    display: block;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: .16px;
    color: $navy;
    text-transform: none;
    margin-bottom: .5rem;
    text-align: left;

    @media (min-width: $media-md) {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: .25px;
    }
  }

  .quotes__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}