/****************************
 * Font Sizes
 *****************************
 *
 * Provides the following helper classes:
 *
 * .font-size-{breakpoint}-{size}
 *
 * Font size: Brand color name (see _variables.scss)
 * Breakpoint: xl, lg, md, sm, xl (see _variables.scss)
 *
 * Examples:
 * .font-size-1:
 * .font-size-md-5
 */

@mixin makeFontSize($value, $size: null, $breakpoint: null) {

  @if $breakpoint {
    $breakpoint: "-#{$breakpoint}"
  }

  .font-size#{$breakpoint}-#{$size} {
    @include text {
      font-size: $value !important;
    }
  }
}

@mixin makeFontSizes($breakpoint: null) {
  $bp: null;

  @if $breakpoint {
    $bp: "-#{$breakpoint}"
  }

  .font-size#{$bp}-tiny {
    font-size: $font-size-tiny;
  }
  .font-size#{bg}-secondary {
    font-size: $font-size-secondary;
  }
  .font-size#{$bp}-small {
    font-size: $font-size-small;
  }
  .font-size#{$bp}-base {
    font-size: $font-size-base;
  }
  .font-size#{$bp}-medium {
    font-size: $font-size-medium;
  }
  .font-size#{$bp}-large {
    font-size: $font-size-large;
  }
  .font-size#{$bp}-extra-large {
    font-size: $font-size-extra-large;
  }
  .font-size#{$bp}-callout {
    font-size: $font-size-callout;
  }
  .font-size#{$bp}-display {
    font-size: $font-size-display;
  }
  .font-size#{$bp}-jumbo {
    font-size: $font-size-jumbo;
  }
  .font-size#{$bp}-stat {
    font-size: $font-size-stat;
  }

  @each $size, $value in $font-sizes {
    @include makeFontSize($value, $size, $breakpoint);
  }
}

@include makeFontSizes;

//Make the responsive breakpoints
@each $breakpoint, $value in $breakpoints {
  @media (min-width: $value) {
    @include makeFontSizes($breakpoint);
  }
}