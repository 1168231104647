@import "../variables";
@import "../mixins";
@import "../text";

$overlay-offset: 150px;
$mobile-overlay-offset: 75px;

.hero .hero__image,
.hero.hero-image .image {
  @include position-fit;
  object-fit: cover;
  object-position: center center;
  z-index: -1;
}

.hero .wrapper {
  min-height: var(--hero-height, 662px);
}

.hero .text {
  width: 37%;
}

.hero__outer {
  padding-bottom: calc(var(--overlay-height) - #{$overlay-offset});
  position: relative;
  z-index: 0;
}

.hero__audima {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}


.hero__heading h1 {
  margin-top: 0;
}

.hero {
  position: relative;
  padding: 0;


  &__nav {
    margin: $spacer * 7 0 0;
    padding-left: 0px;

    h5 {
      padding-bottom: 5px;
      font-size: $font-size-h5;
      border-bottom: 4px solid transparent;

      &:hover {
        border-bottom-color: white;
      }
    }

    li {
      display: inline-block;
      padding: 0 30px;

      &.current {
        h5 {
          border-bottom-color: white;
        }
      }
    }

    li h4 {
      color: white;
      letter-spacing: 1.1px;
    }
  }

  &__body__container {
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &__body {
    width: 100%;
    padding: 75px 0;
    overflow: hidden;
  }

  &:not(.has-overlay).text-placement-bottom .hero__body {
    padding-bottom: $spacing-tiny;
  }

  &__wrapper {
    min-height: 600px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    height: 100%;
  }

  &__accents {
    pointer-events: none;
  }

  &__content {
    padding-top: var(--hero-content-space, 0);

    blockquote {
      font-size: $font-size-h1;
      line-height: 1.4em;
    }

    &__heading {
      position: relative;
      z-index: map_get($z-index, hero-heading);
      margin: $spacing-m 0 $spacing-m;

      h1 {
        font-size: $font-size-h1;
        margin-bottom: 0px;
      }

      &__line {
        position: relative;
        padding: 0.5em 0.5em 0.5em 0;
        display: inline-block;
        background-color: $malala;
        white-space: nowrap;

        &:after {
          content: " ";
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: -100vw;
          width: 100vw;
          background-color: $malala;
        }
      }

      &__word {
        color: $white;
        padding: 0 0.3em 0 0;
      }
    }

    &__body {
      max-width: 40%;

      img.signature {
        display: none;
      }

      .signature--malala {
        svg {
          margin-left: auto;
          display: block;
        }

        position: relative;
        margin-bottom: -70px;
        margin-right: 25px;
        z-index: map_get($z-index, hero-signature);
        min-width: 80px;
        top: -15px;
      }
    }
  }

  .hero__content__body .give-lively-wrap {
    min-width: 400px;
  }

  &.text-alignment-center {
    .hero__content {
      text-align: center;
    }
  }

  &.text-alignment-left {
    .hero__heading h1 {
      text-align: left;
    }
  }

  &.text-alignment-right {
    .hero__content {
      text-align: right;

      &__heading__line {
        padding: 0.5em 0 0.5em 0.5em;

        &:after {
          top: 0;
          bottom: 0;
          left: auto;
          right: -100vw;
        }
      }

      &__heading__word {
        color: $white;
        padding: 0 0 0 0.3em;
      }

      &__body {
        text-align: left;
        margin-left: auto;

        p:first-child img.signature {
          top: -80px;
        }
      }
    }
  }

  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    overflow: visible;
    width: 100%;
    z-index: map_get($z-index, hero-overlay);

    &__wrapper {
      transform: translateY(-$overlay-offset);
    }

    .hero__lead {
      padding: $spacing-xl;
      position: relative;
      background-color: $white;

      .hero__heading h1 {
        font-family: $font-family-sans-serif;
        font-size: $font-size-base;
        font-weight: $font-weight-black;
        line-height: 1.5;
        letter-spacing: 1px;
        text-align: center;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
    }

    p b:only-child,
    p strong:only-child {
      padding-top: 53px;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.22px;
      text-align: left;

      @media (max-width: $media-sm-max) {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: .2px;
      }
    }
  }

  .pattern-banner {
    height: 40px;
    margin-top: 0px;
    background-size: 40px 40px;
  }

  &.text-display {
    .hero__content {
      h1 {
        font-size: $font-size-h1 * 2.1;
      }
    }
  }
}

.hero__body .column-md-half .hero__heading h1 {
  margin-top: 10px;
}

.hero__lead:not(.hero__lead--has-columns) {
  h2 {
    text-align: center;
    margin-top: 0px;
    font-size: $font-size-jumbo;
    font-weight: $font-weight-light;
    font-family: $font-family-sans-serif;
    color: $navy;
    letter-spacing: normal;
    margin-bottom: 53px;
  }
}

.hero__lead__content {
  strong:only-child,
  b:only-child {
    color: $malala;
  }
}

.hero__outer .hero__below {
  padding-bottom: $gutter-width;
}

.hero__outer .hero__below .hero__lead {
  h1 {
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    font-weight: $font-weight-black;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: center;
    color: $black;
    text-transform: uppercase;
  }
}

.hero.text-placement-right .hero__content {
  display: flex;
  justify-content: flex-end;
}

.hero.text-placement-middle .padding-top-overlay {
  padding-top: $overlay-offset + 150px;
}

.hero.text-placement-middle .padding-bottom-overlay {
  padding-bottom: $overlay-offset + 115px;
}

.hero.text-placement-bottom .padding-bottom-overlay {
  padding-bottom: $overlay-offset;
}

.hero.text-placement-bottom .hero__body__container {
  flex-direction: column;
  justify-content: flex-end;
}

.hero.text-shadow .hero__content__body {
  @include text {
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  }
}

@media (min-width: $media-xl) {
  .hero__lead--narrow .hero__lead__content p {
    padding-left: 60px;
    padding-right: 60px;
  }

  .hero__lead--narrow .hero__lead__content {
    max-width: 960px;
  }
}

@media (min-width: $media-lg) {
  .hero.hero-image.hero-image--text-shadow .hero-image__content-body {
    @include text {
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    }
  }

  .hero.hero-image.hero-image--gradient-overlay .wrapper {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );
  }

  .hero.hero-image.hero-image--align-top .wrapper {
    align-items: flex-start;
  }

  .hero.hero-image.hero-image--align-middle .wrapper {
    align-items: center;
  }

  .hero.hero-image.hero-image--align-bottom .wrapper {
    align-items: flex-end;
  }

  .hero__lead__content {
    strong:only-child,
    b:only-child {
      font-size: $font-size-extra-large;
      line-height: 1.45;
    }

    @include text {
      &:first-child {
        margin-top: 0px;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }

    p {
      margin-top: 1.5em;
      margin-bottom: 1.5em;
    }
  }

  .hero.full-screen {
    .hero__body__container {
      padding-bottom: calc(var(--overlay-height) + 150px);
    }

    .hero__overlay__wrapper {
      transform: translateY(calc(-100% - 150px));
    }
  }

  .hero__outer.full-screen {
    padding-bottom: 0px;
  }
}

@media (min-width: $media-lg) and (max-width: $media-xxl-max) {
  body .hero {
    .hero__lead:not(.hero__lead--has-columns) {
      h2 {
        font-size: $font-size-display;
        letter-spacing: 0.19px;
        margin-bottom: 35px;
        line-height: normal;
      }
    }

    .hero__lead__content {
      strong:only-child,
      b:only-child {
        font-size: $font-size-large;
      }
    }

    .hero__wrapper {
      min-height: 420px;
    }
  }
}

@media (min-width: $media-md) {
  .hero.full-screen {
    height: calc(100vh - #{$header-height});
    min-height: 800px;
  }
}

@media (max-width: $media-md-max) {
  .hero.hero-image.hero-image--mobile-align-top .wrapper {
    align-items: flex-start;
  }

  .hero.hero-image.hero-image--mobile-align-middle .wrapper {
    align-items: center;
  }

  .hero.hero-image.hero-image--mobile-align-bottom .wrapper {
    align-items: flex-end;
  }

  .hero.hero-image.hero-image--mobile-text-shadow .hero-image__content-body {
    @include text {
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    }
  }

  .hero.hero-image.hero-image--mobile-gradient-overlay .wrapper {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );
  }

  .hero__lead:not(.hero__lead--has-columns) {
    h2 {
      font-size: $font-size-callout;
      letter-spacing: 0.19px;
      margin-bottom: $spacing-m;
    }
  }

  .hero__content {
    padding-top: var(--hero-mobile-content-space, 0);
  }
  .hero__wrapper {
    min-height: 400px;
    justify-content: initial;
  }

  .hero.full-screen {
    .hero__wrapper {
      max-height: none;
    }

    .hero__overlay__wrapper {
      transform: translateY(-35vh);
    }
  }

  .hero.text-alignment-right {
    .hero__content__body {
      .signature {
        margin-left: 70% !important;
        margin-right: 0 !important;
      }
    }
  }

  .hero__overlay__content {
    h2 {
      font-size: $font-size-h2;
    }
  }
}

@media (max-width: 658px) {
  .hero.text-color-malala {
    .hero__wrapper .hero__heading,
    .hero__content__body {
      @include text {
        color: $malala;
      }
    }
  }

  .hero.text-color-white {
    .hero__wrapper .hero__heading,
    .hero__content__body {
      @include text {
        color: $white;
      }
    }
  }
}

@media (min-width: 659px) {
  .hero.text-color-sm-malala {
    .hero__wrapper .hero__heading,
    .hero__content__body {
      @include text {
        color: $malala;
      }
    }
  }

  .hero.text-color-sm-white {
    .hero__wrapper .hero__heading,
    .hero__content__body {
      @include text {
        color: $white;
      }
    }
  }
}

@media (max-width: $media-sm-max) {
  .hero__overlay__wrapper .hero__lead .hero__heading h1 {
    margin-bottom: 15px;
  }

  .hero__lead:not(.hero__lead--has-columns) {
    h2 {
      margin-bottom: $spacing-s;
    }
  }

  .hero__lead__content {
    padding-left: 0px;
    padding-right: 0px;
  }

  .hero__wrapper {
    overflow: hidden;
    height: calc(90vh - #{$mobile-header-height});
    max-height: 521px;
  }

  .hero .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .hero__content {
    @include set-font-sizes(0.8);
  }

  .hero__content__body {
    max-width: 100% !important;

    img.signature {
      top: -10px;
    }

    p:first-child img.signature {
      top: -40px;
    }
  }

  .hero__heading {
    h1 {
      margin-top: 0;
      font-size: $font-size-h1 * 0.7;
    }
  }

  .hero__overlay__content,
  .hero__overlay__secondary__content {
    margin-bottom: $spacer;
  }

  .hero__overlay > .container {
    padding-left: 0;
    padding-right: 0;

    #give-lively-widget {
      min-height: 200px;
    }
  }
}

@media (max-width: $media-lg-max) {
  .hero .wrapper {
    min-height: 460px;
  }
}

@media (max-width: $media-md-max) {
  .hero .hero__content__body {
    max-width: 284px !important;
  }

  .hero .hero__content blockquote {
    font-size: $font-size-extra-large;
    line-height: 32px;
  }

  .hero__outer.full-screen {
    padding-bottom: calc(
      var(--overlay-height) / 1.5 - #{$mobile-overlay-offset}
    );
  }
}

@media (max-width: $media-sm-max) {
  .hero .wrapper {
    min-height: var(--hero-height-mobile, 400px);
  }

  .hero__outer {
    padding-bottom: calc(var(--overlay-height) - #{$mobile-overlay-offset});
  }

  .hero__overlay .hero__overlay__wrapper {
    transform: translateY(-$mobile-overlay-offset);
  }

  .hero.text-placement-middle .padding-top-overlay {
    padding-top: $mobile-overlay-offset + 150px;
  }

  .hero.text-placement-middle .padding-bottom-overlay {
    padding-bottom: $mobile-overlay-offset + 115px;
  }

  .hero.text-placement-bottom .padding-bottom-overlay {
    padding-bottom: $mobile-overlay-offset;
  }

  .hero__overlay__wrapper .hero__lead {
    padding-left: 25px;
    padding-right: 25px;
  }

  .hero .hero__overlay__wrapper .container {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  .hero.with-overlay .wrapper {
    height: 450px;
  }

  .hero .text {
    width: 50%;
  }

  .hero.no-image-mobile {
    .image {
      background: $malala !important;
    }
  }

  .hero--homepage-video__outer {
    position: relative;
    z-index: 2;

    &:before {
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      content: "";
      background-image: url("~images/accents/flower-icon-cropped.png");
      height: 180px;
      width: 102px;
      bottom: -60px;
      right: 0;
    }

    .hero__wrapper {
      max-height: 100vh;
      height: calc(100vh - 30px);
    }

    .hero__below-outer {
      position: absolute;
      bottom: 15px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      &:before,
      &:after {
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        content: "";
      }

      &:before {
        background-image: url("~images/accents/accent-5-white.svg");
        height: 70px;
        width: 70px;
        top: -105px;
        left: 10px;
      }

      &:after {
        background-image: url("~images/accents/accent-18-outline-white.svg");
        height: 61px;
        width: 60px;
        top: -20px;
        left: -28px;
      }
    }

    .button--link:hover,
    .button--link,
    .hero__lead {
      @include text {
        color: $white;
      }
    }
  }

  .hero-image__content-body {
    cite,
    blockquote {
      font-size: $font-size-medium;
    }
  }
}

@media (max-width: $media-xxs-max) {
  .hero-image__content-body {
    cite,
    blockquote {
      font-size: $font-size-secondary;
    }
  }
}
