@import "../variables";
@import "../mixins";

.countries-grid {
  @media (max-width: $media-sm-max) {
    .card {
      max-width: none;
    }

    .card__body {
      padding-bottom: 8px;
    }

    .card__image__wrapper {
      display: none;
    }
  }
}
