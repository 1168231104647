body section.section--bottom-padding-tall.section--donate-questions {
  @media  (max-width: $media-sm-max) {
    padding-bottom: 125px;
  }

  @media  (min-width: $media-md) {
    padding-top: 100px;
    padding-bottom: 225px;
  }
}

body section.section--donate-questions {
  .container.section__text {
    max-width: 600px;
  }

  @media (max-width: $media-sm-max) {
    h2 {
      font-size: 7vw;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
    }
  }
}

body section.section--donate-questions-alt {
  @media (min-width: $media-md) {
    h2 {
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.25px;
    }
  }

  @media (max-width: $media-sm-max) {
    h2 {
      font-size: 6vw;
    }
  }
}