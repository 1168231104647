@import "../variables";
@import "../mixins";

.link-carousel {
  --swiper-pagination-color: #{$peach};

  .swiper-wrapper {
    align-items: stretch;
  }

  .link-carousel__link {
    display: flex;
    align-items: flex-start;
    color: inherit;
    text-decoration: none;
    height: 100%;
    box-sizing: border-box;

    &:hover {
      text-decoration: none;
    }

    .link__content {
      flex-grow: 1;
      padding-right: 15px;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
    }

    .link__title {
      display: block;
      font-size: $font-size-medium;
      margin-bottom: 10px;
    }

    .link__date {
      font-weight: bold;
      font-size: $font-size-tiny;
      margin-bottom: 25px;
      flex-grow: 1;
    }

    .link__source {
      font-weight: $font-weight-light;
      font-size: $font-size-medium;
      margin-bottom: 15px;
    }
  }

  .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
  }

  .link-carousel__link {
    border-top: solid 3px $navy;
    padding-top: 40px;
    padding-right: 25px;
    padding-bottom: 20px;
  }

  .swiper-slide:not(:first-child) .link-carousel__link {
    padding-left: 25px;
  }

  .swiper-slide:not(:last-child) .link-carousel__link {
    border-right: solid 1px $navy;
  }
}
