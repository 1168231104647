@import "../variables";
@import "../mixins";

section.section--updates-card,
section.section--componentUpdatesCard {
  .column-full,
  .component--componentUpdatesCard,
  .component--componentUpdatesCard > div,
  .updates-card__wrapper {
    display: flex;
    align-content: stretch;
  }

  .updates-card {
    @include set-page-gutter-attribute(".card__body", padding-right);

    .card__body {
      padding-left: 57px;
      padding-top: 48px;
      padding-bottom: 48px;
    }

    h3 {
      margin-bottom: 1px;
      font-family: $font-family-sans-serif;
      font-size: $font-size-base;
      font-weight: normal;
      letter-spacing: 1px;
    }
  }

  @media (max-width: $media-sm-max) {
    overflow: hidden;

    .updates-card {
      margin-left: -$page-gutter-width-sm;
      margin-right: -$page-gutter-width-sm;
      max-width: initial;
      width: 100vw;
    }

    &.section--bottom-padding-standard .updates-card {
      bottom: -$section-padding - 64px;
      margin-top: -$section-padding - 64px;
    }
  }
}
