.stat {
  font-size: $font-size-stat;
  font-family: $font-family-serif;
  font-weight: $font-weight-semibold;
  line-height: 1;
}

@media (max-width: $media-sm-max) {
  .stat {
    font-size: $font-size-large;
  }
}

@media (max-width: $media-xxl-max) {
  .stat {
    font-size: $font-size-jumbo;
  }
}
