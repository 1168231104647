@import "../variables";
@import "../mixins";

.audima-icon {
  #audima-iframe {
    body {
      margin: 0;
    }
    .audima-player *:not(#play-pause-btn) {
      display: none;
    }
  }

  max-height: 47px;
  position: relative;
  left: -10px;
  top: -10px;
  width: 70px;
  overflow: hidden;
}

.audima-icon__wrapper {
  overflow: hidden;
  margin: 0 10px 0px;
  width: 46px;
  transform: translateY(4px);
}
