@import "variables";
@import "mixins";

label {
  display: block;
  letter-spacing: $input-letter-spacing;
  text-transform: uppercase;
  font-size: $input-font-size;
  font-weight: bold;
  margin-bottom: $spacing-xs;
}

input[type="text"],
select,
textarea {
  font-family: $font-family-sans-serif;
  border: $input-border-width solid $input-color;
  border-radius: $input-border-radius;
  padding: $input-padding-y $input-padding-x;
  line-height: $input-line-height;
  font-size: $input-font-size;
  box-shadow: inset 0 0 0 0.2px $input-color;
}

input[type="email"] {
  font-family: $font-family-sans-serif;
  border: $input-border-width solid $input-color;
  border-radius: $input-border-radius;
  padding: $input-padding-y $input-padding-x;
  line-height: $input-line-height;
  font-size: $input-font-size;
  box-shadow: inset 0 0 0 0.2px $input-color;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: white;
}

textarea {
  min-height: 150px;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
textarea::placeholder {
  color: $dark-gray;
  font-style: italic;
}

form {
  .alert {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    font-size: $font-size-small;
    line-height: 1em;

    &--success {
      background-color: $peace;
    }

    &--error {
      background-color: $rani;
    }
  }

  button.button,
  input[type="submit"] {
    box-shadow: inset 0 0 0 0.2px $input-color;
    border-width: $input-border-width;
  }
}

.has-form--horizontal form,
form.form--horizontal {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;

  input,
  select,
  button {
    margin-bottom: $spacing-base;
  }
}

.react-select__value-container {
  height: 48px;
}

form.champions__search__filters {
  display: flex;
  margin-bottom: 60px;
  margin-left: 70px;
  justify-content: center;
}

.css-2b097c-container {
  position: relative;
  margin-right: 24px;
  box-sizing: border-box;
  width: 318px;
}

input#react-select-2-input {
  width: 0px !important;
}

input#react-select-3-input {
  width: 0px !important;
}

span.react-select__indicator-separator.css-1wcmos9-indicatorSeparator {
  background-color: white;
  display: none;
}

body {
  .react-select__control {
    border-color: $zia;
    width: 318px;
    height: 48px;
    color: $zia;
    font-size: $font-size-h5;
    font-family: $font-family-sans-serif;
  }

  .react-select__value-container {
    font-size: 11px;
    font-weight: $font-weight-bold;
    line-height: 1.27;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-left: 20px;
  }

  .react-select__single-value {
    font-size: $font-size-h5;
    text-transform: uppercase;
    font-family: $font-family-sans-serif;
    font-weight: bold;
  }

  .react-select__option {
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    font-size: 11px;
    border-bottom: solid 1px $warm-gray;
    padding: 10px 20px;
  }

  .react-select__menu-list {
    text-align: left;
  }

  .react-select__placeholder {
    color: $navy;
  }
}

.form--dark {
  input[type="email"],
  input[type="text"],
  select,
  textarea {
    border: solid 0 white;
    border-radius: 4px;
    padding: 12.5px;
    padding-left: 25px;

    &::placeholder {
      color: $cool-gray;
      font-style: italic;
    }
  }

  input[type="submit"],
  .button {
    @include transition;
    display: inline-block;
    border: $btn-border-width solid $peace;
    border-radius: $btn-border-radius;
    padding: $btn-padding-y + .5 50px;
    width: fit-content;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: center;
    font-family: $font-family-sans-serif;
    font-size: $btn-font-size;
    text-transform: uppercase;
    font-weight: $btn-font-weight;
    letter-spacing: $btn-letter-spacing;
    line-height: 1;
    cursor: pointer;
    color: $white;
    background-color: $cobalt;
    border-color: $cobalt;

    &.button--secondary {
      @extend .button--secondary !optional;
    }

    &.button--light {
      @extend .button--light !optional;
      box-shadow: none;
    }

    &:hover {
      background-color: $peace-l1;
      color: $white !important;
      text-decoration: none;
      border-color: $peace-l1;
    }
  }
}

.form--light {
  input[type="submit"],
  .button {
    @include transition;
    display: inline-block;
    border: $btn-border-width solid $white;
    border-radius: $btn-border-radius;
    padding: $btn-padding-y 50px;
    width: fit-content;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: center;
    font-family: $font-family-sans-serif;
    font-size: $btn-font-size;
    text-transform: uppercase;
    font-weight: $btn-font-weight;
    letter-spacing: $btn-letter-spacing;
    line-height: 1;
    cursor: pointer;
    color: $white;
    background-color: transparent;
    border-color: $white;

    &:hover {
      background-color: $peace-l1;
      color: $white !important;
      text-decoration: none;
      border-color: $peace-l1;
    }
  }
}

@media (max-width: $media-xl-max) {
  input[type="email"],
  input[type="text"],
  select,
  textarea {
    font-size: $input-font-size - 3;
  }
}

@media (max-width: $media-xs-max) {
  input[type="submit"],
  .button {
    margin-bottom: 15px;

    & + .button {
      margin-left: 0px;
    }
  }
}
