@import "../variables";
@import "../mixins";

.accents {
  pointer-events: none;
}

.accent-images {
  position: relative;
  z-index: 2;
  pointer-events: none;

  .container {
    height: 100%;
  }

  .accent {
    position: absolute;
    width: var(--accent-width, 50px);
    top: var(--accent-top, unset);
    right: var(--accent-right, unset);
    bottom: var(--accent-bottom, unset);
    left: var(--accent-left, unset);
    transition: transform 30ms linear;
    z-index: var(--accent--z, 1);
    height: var(--height, auto);
    object-fit: contain;
  }

  .accent--parallax {
    will-change: transform;
    transform: translateY(
      calc(var(--parallax-translate-y, 0) * var(--accent-speed, 1))
    );
  }

  .accent--desktop,
  .accent--tablet,
  .accent--mobile {
    display: none;
  }

  @media only screen and (min-width: $media-lg) {
    .accent--desktop {
      display: block;
    }
  }

  @media only screen and (min-width: $media-md) and (max-width: $media-md-max) {
    .accent--tablet {
      display: block;
    }
  }

  @media only screen and (max-width: $media-sm-max) {
    .accent--mobile {
      display: block;
    }
  }
}
