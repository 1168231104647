@import "../variables";
@import "../mixins";

body .swiper-cards {
  @media (max-width: $media-md-max) {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
