@import "../variables";

section.section--approach {
  .accent {
    z-index: 2;
    position: absolute;
    right: 30%;
    top: 0;
    transform: rotate(45deg);
  }

  @media (max-width: $media-xxl-max) {
    .accent {
      width: 75px;
      right: 25%;
    }
  }
}
