
/****************************
 * Padding Global Helpers
 *****************************
 *
 * Provides the following helper classes:
 *
 * .padding-{direction}-{breakpoint}-{amount}
 *
 * Direction: top, right, bottom, left, x (left and right), y (top and bottom)
 * Breakpoint: xl, lg, md, sm, xl (see _variables.scss)
 * Amount: 1 - 9 (see spacer values in _variables.scss)
 *
 * Examples:
 * .padding: padding on all sides
 * .padding-sm: padding on all size targeting only small devices and higher
 * .padding-bottom-3: A spacer level of 3 padding on the bottom
 * .padding-bottom-xl-3: A spacer level of 3 of padding targeting only extra large devices and higher.
 */
@mixin makePadding($value, $spacer: null, $breakpoint: null) {
  @if $spacer {
    $spacer: "-#{$spacer}"
  }

  @if $breakpoint {
    $breakpoint: "-#{$breakpoint}"
  }

  .padding#{$breakpoint}#{$spacer} {
    padding: $value !important;
  }

  .padding-bottom#{$breakpoint}#{$spacer} {
    padding-bottom: $value !important;
  }

  .padding-left#{$breakpoint}#{$spacer} {
    padding-left: $value !important;
  }

  .padding-right#{$breakpoint}#{$spacer} {
    padding-right: $value !important;
  }

  .padding-top#{$breakpoint}#{$spacer} {
    padding-top: $value !important;
  }

  .padding-x#{$breakpoint}#{$spacer} {
    padding-left: $value !important;
    padding-right: $value !important;
  }

  .padding-y#{$breakpoint}#{$spacer} {
    padding-top: $value !important;
    padding-bottom: $value !important;
  }

  .padding-right-gutter {
    padding-right: $gutter-width*2;
  }

  .padding-left-gutter {
    padding-left: $gutter-width*2;
  }
}

@mixin makePaddings($breakpoint: null) {
  //The default spacer size
  @include makePadding(map_get($spacers, 7), null, $breakpoint);

  //Make all the sizes
  @each $spacer,
  $value in $spacers {
    @include makePadding($value, $spacer, $breakpoint);
  }
}

@include makePaddings();

//Make the responsive breakpoints
@each $breakpoint,
$value in $breakpoints {

  //The default spacer size
  @media (min-width: $value) {
    @include makePaddings($breakpoint);
  }
}

@include set-gutter-attribute('.padding-left-gutter', padding-left);
@include set-gutter-attribute('.padding-top-gutter', padding-top);
@include set-gutter-attribute('.padding-right-gutter', padding-right);
@include set-gutter-attribute('.padding-bottom-gutter', padding-bottom);
@include set-page-gutter-attribute('.padding-left-page-gutter', padding-left);
@include set-page-gutter-attribute('.padding-right-page-gutter', padding-right);
@include set-container-gutter-attribute('.padding-left-container-gutter', padding-left);
@include set-container-gutter-attribute('.padding-right-container-gutter', padding-left);