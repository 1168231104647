.section--follow-collage {
  div:nth-child(1) {
    order: 1;
    grid-row: 1;
  }

  div:nth-child(2) {
    order: 2;
    grid-row: 2;
  }

  div:nth-child(3) {
    transform: translateY(-20px);
    order: 3;
    grid-row: 1 / 3;
  }

  div:nth-child(4) {
    order: 4;
    grid-column: 3 / 5;
    grid-row: 1 / 3;
  }

  h3 {
    font-weight: $font-weight-bold;
    color: $cobalt;
    margin-bottom: 0;
    margin-top: 0;
  }

  p {
    margin-top: 5px;
  }

  .social-icons {
    padding-top: 20px;
  }

  .social-icons__icon {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }

  .grid__content {
    max-width: 320px;
    padding-bottom: 15px;
  }
}