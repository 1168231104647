/****************************
 * Text Alignment
 *****************************
 *
 * Provides the following helper classes:
 *
 * .text-{alignment}-{breakpoint}
 *
 * Alignment: left, right, center
 * Breakpoint: xl, lg, md, sm, xl (see _variables.scss)
 *
 * Examples:
 * .text-center: Center align text
 * .text-left-sm: Left align text for small devices and higher
 */

@mixin makeAlignments($breakpoint: null) {
  @if $breakpoint {
    $breakpoint: "-#{$breakpoint}";
  }

  .text#{$breakpoint}-center,
  .text-center#{$breakpoint} {
    text-align: center;
  }

  .text#{$breakpoint}-left,
  .text-left#{$breakpoint} {
    text-align: left;
  }

  .text#{$breakpoint}-right,
  .text-right#{$breakpoint} {
    text-align: right;
  }
}

@include makeAlignments;

//Make the responsive breakpoints
@each $breakpoint, $value in $breakpoints {
  @media (min-width: $value) {
    @include makeAlignments($breakpoint);
  }
}