/****************************
 * Borders
 *****************************
 *
 * Provides the following helper classes:
 *
 * .border-{breakpoint}-{color}
 *
 * Color: Brand color name (see _variables.scss)
 * Breakpoint: xl, lg, md, sm, xl (see _variables.scss)
 *
 * Examples:
 * .border-malala
 * .border-sm-black
 */

@mixin makeBorder($value, $color: null, $breakpoint: null) {
  @if $breakpoint {
    $breakpoint: "-#{$breakpoint}";
  }

  @if $color {
    $color: "-#{'' + $color}";
  } @else {
    $color: "";
  }

  .border-color#{$breakpoint}#{"" + $color} {
    border-color: $value;

    .container {
      border-color: $value;
    }
  }

  .border#{$breakpoint}#{"" + $color} {
    border: solid 1px #{$value};
  }

  .border-top#{$breakpoint}#{"" + $color} {
    border-top: solid 1px #{$value};
  }

  .border-right#{$breakpoint}#{"" + $color} {
    border-right: solid 1px #{$value};
  }

  .border-bottom#{$breakpoint}#{"" + $color} {
    border-bottom: solid 1px #{$value};
  }

  .border-left#{$breakpoint}#{"" + $color} {
    border-left: solid 1px #{$value};
  }

  .border-top#{$breakpoint}-none {
    border-top: none;
  }

  .border-right#{$breakpoint}-none {
    border-right: none;
  }

  .border-bottom#{$breakpoint}-none {
    border-bottom: none;
  }

  .border-left#{$breakpoint}-none {
    border-left: none;
  }
}

@mixin makeBorders($breakpoint: null) {
  @include makeBorder(null, null, $breakpoint);
  @each $color, $value in $colors {
    @include makeBorder($value, $color, $breakpoint);
  }
}

@include makeBorders;

//Make the responsive breakpoints
@each $breakpoint, $value in $breakpoints {
  @media (min-width: $value) {
    @include makeBorders($breakpoint);
  }
}
