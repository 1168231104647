/****************************
 * Text Colors
 *****************************
 *
 * Provides the following helper classes:
 *
 * .text-{breakpoint}-{color}
 *
 * Color: Brand color name (see _variables.scss)
 * Breakpoint: xl, lg, md, sm, xl (see _variables.scss)
 *
 * Examples:
 * .text-malala: Center align text
 * .text-sm-black
 */

@mixin makeColor($value, $color: null, $breakpoint: null) {

  @if $breakpoint {
    $breakpoint: "-#{$breakpoint}"
  }

  .text#{$breakpoint}-#{"" + $color} {
    @include text {
      color: $value !important;
    }
  }
}

@mixin makeColors($breakpoint: null) {
  @each $color, $value in $colors {
    @include makeColor($value, $color, $breakpoint);
  }
}

@include makeColors;

//Make the responsive breakpoints
@each $breakpoint, $value in $breakpoints {
  @media (min-width: $value) {
    @include makeColors($breakpoint);
  }
}