@import "../variables";
@import "../mixins";

.drawer {
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
}

.drawer__content {
  max-height: 0px;
  overflow: hidden;
  max-width: 700px;
}

.drawer__content p:first-child {
  margin-top: 0;
}

.drawer__content p:last-child {
  margin-bottom: 0;
}

.drawer__header {
  padding: 10px 0;
  cursor: pointer;
}

.drawer__header-content {
  max-width: calc(100% - 50px);
}

.drawer--expanded .drawer__content {
  max-height: initial;
}

.drawer__icon {
  width: 26px;
  height: 26px;
}