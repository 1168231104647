.section--our-investment__accents {
  .accent {
    position: absolute;
  }
}

@media (min-width: $media-lg) {
  .section--our-investment__accents {
    .accent--large {
      top: -25px;
      left: 0;
    }

    .accent--small {
      top: 120px;
      left: -75px;
    }

    .accent--extra-large {
      display: none;
    }
  }
}


@media (max-width: $media-md-max) {
  .section--our-investment {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .section--our-investment__accents {
    .accent--extra-large {
      top: -20px;
      right: 0;
    }

    .accent--large {
      top: 146px;
      right: 0;
      width: 50px;
    }

    .accent--small {
      display: none;
    }
  }
}

