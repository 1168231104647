@import "../variables";
@import "../mixins";

.translation-widget {
  margin-bottom: 30px;
}

.translation-widget label {
  font-weight: normal;
  line-height: 1.67;
  letter-spacing: 0.32px;
  text-align: center;
  color: $navy;
  margin-bottom: 10px;
  font-size: 12px;
  display: block;
}

.translation-widget .weglot-select > div {
  margin-right: 0px !important;
}

.translation-widget__inner {
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 4px;
  border: solid 1px $navy;
}

body .translation-widget .css-2b097c-container,
body .translation-widget .react-select__control {
  max-width: 48vw;
  width: 240px;
  border: none;
}

.translation-widget__audima {
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: solid 1px $navy;
}
