@mixin position-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin position-right($right: 0, $top: 50%) {
  position: absolute;
  top: $top;
  right: $right;
  transform: translate(0, -50%);
}

@mixin position-left($left: 0, $top: 50%) {
  position: absolute;
  top: $top;
  left: $left;
  transform: translate(0, -50%);
}

@mixin position-top($top: 0, $left: 50%) {
  position: absolute;
  top: $top;
  left: $left;
  transform: translate(-$left,  0);
}

@mixin position-bottom($bottom: 0, $left: 50%) {
  position: absolute;
  bottom: $bottom;
  left: $left;
  transform: translate(-$left,  0);
}

@mixin position-bottom-left ($bottom: 0, $left: 0) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

@mixin position-bottom-right ($bottom: 0, $right: 0) {
  position: absolute;
  bottom: $bottom;
  right: $right;
}

@mixin position-top-left ($top: 0, $left: 0) {
  position: absolute;
  top: $top;
  left: $left;
}

@mixin position-top-right ($top: 0, $right: 0) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin position-reset() {
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  transform: none;
}

@mixin position-fit ($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  width: 100%;
  height: 100%;
}