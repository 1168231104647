section.section--read-assembly {
  padding-bottom: 300px !important;

  .image--flipbook__image-wrapper {
    position: relative;

    .accent--0 {
      width: 610px;
      position: absolute;
      z-index: 3;
      bottom: -98px;
      left: -70px;
      max-width: 30vw;
      min-width: 200px;
    }

    .accent--1 {
      width: 253px;
      position: absolute;
      z-index: 3;
      top: -69px;
      right: 22px;
      max-width: 20vw;
      min-width: 150px;
    }

    .image-flipbook__image {
      position: relative;
      z-index: 2;
    }

    &:after {
      position: absolute;
      content: "";
      display: block;
      background-color: $peace-l1;
      right: 130px;
      bottom: -$gutter-width*0.5;
      width: 60%;
      height: 60%;
      z-index: 1;
    }
  }

  .accent--main--0 {
    position: absolute;
    bottom: -25px;
    left: 0;
  }

  .image-flipbook__text {
    padding-left: 0px;
  }

  @media (max-width: $media-xxl-max) {
    padding-bottom: 200px !important;

    .image--flipbook__image-wrapper {
      padding-left: $gutter-width;
      padding-right: 0;

      .accent--1 {
        right: -145px;
      }

      .accent--2 {
        left: -55px;
      }

      &:after {
        right: -20px;
      }
    }

    .image-flipbook__text {
      padding-left: 110px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  @media (min-width: $media-xxxl) {
    .image--flipbook__image-wrapper {
      position: relative;
      padding-right: 150px;
      padding-left: 35px;
    }
  }

  @media (min-width: $media-lg) {
    .image-flipbook__text-container.width-lg-40 {
      width: 43%;
      max-width: 43%;
    }

    .image-flipbook__image-container.width-lg-60 {
      width: 57%;
      max-width: 57%;
    }
  }

  @media (max-width: $media-md-max) {
    padding-bottom: 0px !important;

    .accent--main--0 {
      display: none;
    }

    .image-flipbook__text {
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 0px;
    }

    .image--flipbook__image-wrapper {
      padding-left: 0px;
      padding-right: 0px;

      .accents {
        overflow: hidden;
        top: -50px;
      }

      .accent--0 {
       display: none;
      }

      .accent--1 {
        top: 0;
        right: -64px;
      }

      &:after {
        display: none;
      }
    }
  }
}