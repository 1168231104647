@import "../variables";

.chart--orange {
  --chart-color: #DD6A40;
  --chart-color-2: #F39473;
  --chart-color-1: #F4BBA7;
  --chart-content-color: #131F45;
}

.chart--blue {
  --chart-color: #233F96;
  --chart-color-2: #5B6DCD;
  --chart-color-1: #A3B9F7;
  --chart-content-color: #fff;
}

.chart--navy {
  --chart-color: #131F45;
  --chart-color-2: #2C3A7D;
  --chart-color-1: #5B6DCD;
  --chart-content-color: #fff;
}

.chart--white {
  --chart-color: #c6c6c6;
  --chart-color-2: #e0e0e0;
  --chart-color-1: #f5f5f5;
  --chart-content-color: #131F45;
}


.chart {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
}

.chart--orange {
  --chart-color: #DD6A40;
  --chart-color-2: #F39473;
  --chart-color-1: #F4BBA7;
}

.chart--blue {
  --chart-color: #233F96;
  --chart-color-2: #5B6DCD;
  --chart-color-1: #A3B9F7;
}

.chart--navy {
  --chart-color: #131F45;
  --chart-color-2: #2C3A7D;
  --chart-color-1: #5B6DCD;
}

.chart--white {
  --chart-color: #c6c6c6;
  --chart-color-2: #e0e0e0;
  --chart-color-1: #f5f5f5;
}


.chart {
  display: flex;
  margin: 4rem auto 90px;
  flex-direction: column-reverse;
  align-items: center;
  gap: 2.5rem;
  max-width: 900px;

  @media (min-width: $media-lg) {
    gap: .75rem;
    align-items: flex-end;
    flex-direction: row;
  }
}

.chart__cite {
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: $navy;

  i {
    @media (max-width: $media-md-max) {
      font-style: normal;
    }
  }

  @media (min-width: $media-lg) {
    font-size: 18px;
  }
}

.chart__bars {
  display: flex;
  gap: .2rem;
  width: 100%;

  @media (min-width: $media-lg) {
    gap: .6rem;
  }
}

.chart {
  .bar {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 260px;

    @media (min-width: $media-lg) {
      height: 420px;
    }
  }

  .bar__progress-wrapper {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
  }

  .bar__label {
    flex-grow: 0;
  }

  .bar__progress {
    width: 100%;
    height: var(--bar-height, 0);
    background-color: var(--chart-color, #DD6A40);
    border-top-left-radius: 1.75rem;
    border-top-right-radius: 1.75rem;
    padding: 1rem;
    box-sizing: border-box;
    color: var(--chart-content-color, #131F45);
    justify-content: flex-end;
    text-align: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    margin-bottom: .2rem;

    @media (min-width: $media-lg) {
      margin-bottom: .5rem;
    }
  }

  .bar:last-child .bar__progress-content {
    transform: translateY(2px);
  }

  .chart__y-label {
    font-family: $font-family-serif;
    font-size: .8rem;
    color: var(--chart-content-color, #131F45);
    font-weight: 700;
    margin-top: .1rem;

    @media (min-width: $media-lg) {
      font-size: 1.5rem;
    }
  }

  .chart__x-label {
    font-family: $font-family-serif;
    font-size: 16px;
    line-height: .8;
    text-transform: uppercase;
    color: var(--chart-content-color, #131F45);
    font-weight: 900;

    @media (min-width: $media-lg) {
      text-transform: none;
      font-weight: 700;
    }
  }

  .bar__label {
    border-left: solid 1px var(--chart-content-color, #131F45);
    padding: .3rem .5rem 0;
    font-weight: $font-weight-semibold;
    line-height: .8;
    min-height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: var(--chart-content-color, #131F45);
    font-size: 14px;

    @media (min-width: $media-lg) {
      padding: 1.25rem .5rem 0;
      font-size: 16px;
    }
  }

  .bar__value {
    font-family: $font-family-serif;
    font-size: 40px;
    line-height: .8em;

    @media (min-width: $media-lg) {
      font-size: 6.5rem;
    }
  }

  .bar:nth-child(2) {
    .bar__value {
      @media (min-width: $media-lg) {
        font-size: 5.5rem;
      }
    }

    .bar__progress {
      background-color: var(--chart-color-2, #F39473);
    }
  }


  .bar:nth-child(1) {
    .bar__value {
      @media (min-width: $media-lg) {
        font-size: 4.5rem;
      }
    }

    .bar__progress {
      background-color: var(--chart-color-1, #F4BBA7);
    }
  }
}



