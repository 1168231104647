/****************************
 * Font Weight Global Helpers
 *****************************
 *
 * Provides the following helper classes:
 *
 * .font-weight-{breakpoint}-{weight}
 * .font-weight-{weight}
 *
 * Space is similar to margin, except using margin without a direction targets all sides. Using space without a direction targets only margin bottom.
 *
 * Direction: top, right, bottom, left, x (left and right), y (top and bottom)
 * Breakpoint: xl, lg, md, sm, xl (see _variables.scss)
 * Amount: 1 - 9 (see spacer values in _variables.scss)
 *
 * Examples:
 * .font-weight-bold
 */

@mixin makeWeights($breakpoint: null) {
  @if $breakpoint {
    $breakpoint: "-#{$breakpoint}"
  }

  .font-weight#{$breakpoint}-light {
    font-weight: $font-weight-light !important;
  }

  .font-weight#{$breakpoint}-regular {
    font-weight: $font-weight-regular !important;
  }

  .font-weight#{$breakpoint}-medium {
    font-weight: $font-weight-medium !important;
  }

  .font-weight#{$breakpoint}-semibold {
    font-weight: $font-weight-semibold !important;
  }

  .font-weight#{$breakpoint}-bold {
    font-weight: $font-weight-bold !important;
  }

  .font-weight#{$breakpoint}-black {
    font-weight: $font-weight-black !important;
  }
}

@include makeWeights();

//Make the responsive breakpoints
@each $breakpoint, $value in $breakpoints {
  //The default spacer size
  @media (min-width: $value) {
    @include makeWeights($breakpoint);
  }
}