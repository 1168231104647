.section--girls-education-barriers {
  .image-flipbook__text {
    p, li {
      font-weight: 100;
      line-height: 1.25;
      letter-spacing: 0.4px;
    }
  }

  .button--link {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.63px;
  }

  @media (min-width: $media-md) {
    .section__heading {
      display: none;
    }
    .image-flipbook__image {
      height: 427px;
    }

    h2 {
      margin-bottom: 0px;
    }

    h2 + p {
      margin-top: .5em;
    }

    p, li {
      font-size: $font-size-callout;
    }
  }

  @media (max-width: $media-sm-max) {
    .component {
      margin-bottom: 40px;
    }

    .section__heading {
      margin-top: 25px;
      margin-bottom: 0px;
    }

    .image-flipbook__text {
      margin-bottom: 0;
      padding-top: 0;
      padding-left: 0;
    }

    .image-flipbook {
      .image-flipbook__text h2 {
        display: none;
      }

      image-flipbook__text {
        padding-top: 0px;
        margin-bottom: 0px;
      }
    }

    .image-flipbook__image {
      width: 65vw;
      height: auto;
    }

    .image-flipbook {
      flex-direction: row;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .button--link {
      color: $blue;
    }
  }
}