@import "../variables";
@import "../mixins";

.malalas-story {
  position: relative;
  background-color: $luiza;
  padding-top: 150px;
}

.malalas-story__text {
  width: 40%;
}

@media (max-width: $media-xxl-max) {
  .malalas-story__text {
    width: 460px;
  }
}

@media (max-width: $media-xl-max) {
  .malalas-story__text {
    width: 400px;
  }
}

@media (max-width: $media-lg-max) {
  .malalas-story__text {
    width: 380px;
  }

  .malalas-story {
    position: relative;
    padding: 50px 0 60px 0;
  }
}

@media (max-width: $media-md-max) {
  .malalas-story {
    background-color: white;
    padding: 89px 0 100px 0;
  }
}

@media (max-width: $media-sm-max) {
  .malalas-story {
    padding: 220px 0 200px 0;
  }

  .malalas-story__text {
    width: auto;
  }
}
