@import "../variables";
@import "../mixins";

.champions__search {
  &--loading {
    .champions__search__filters {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  .champions__search__champions {
    &--grid-size-3 {
      .row:nth-child(even) {
        justify-content: flex-end;
      }

      .champions__search__champion:only-child,
      .champions__search__champion:nth-child(2):last-child {
        margin-right: auto;
      }
    }

    &--grid-size-1 {
      .row {
        justify-content: center;
      }
    }
  }

  .champions__search__label {
    .label {
      font-size: $font-size-tiny;
      letter-spacing: 1.36px;
      text-transform: initial;
      margin-right: 24px;
      margin-bottom: 0px;
      white-space: nowrap;
    }
  }

  .champions__search__clear {
    white-space: nowrap;
    img {
      position: relative;
      top: -1px;
    }
  }

  .react-select__control {
    white-space: nowrap;
  }

  .card .card__content {
    line-height: 1.2;
  }

  .card__heading .tag {
    font-size: 11px;
  }

  .card h3 {
    font-weight: $font-weight-semibold;
    line-height: 24px;
    font-size: 20px;
    color: $dark-gray;
    letter-spacing: 0;
    font-family: $font-family-serif;
    margin-bottom: 1px;
  }

  @media (max-width: $media-xxl-max) {
    .card h3 {
      font-size: 17.5px;
    }
  }

  @media (max-width: $media-md-max) {
    form.champions__search__filters {
      flex-direction: column;
      margin-left: 0px;
      align-items: center;

      > div {
        max-width: 100%;
        margin-right: 0px;
      }
    }

    .react-select__control {
      margin: 18px 0;
      max-width: 100%;
    }

    .champions__search__champions {
      display: flex;
      flex-wrap: wrap;
    }
    .column-half {
      width: 41%;
    }
  }

  .card .footer_content {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.28;
  }

  @media (max-width: $media-sm-max) {
    .card .card__heading {
      margin-left: -18px;
      margin-right: -18px;
    }

    .card h3 {
      line-height: 1.2;
    }
  }
}
