@import "../variables";
@import "../mixins";

@mixin set-section-padding($short, $regular, $tall) {
  section.section--no-padding {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  section.section--top-padding-none {
    padding-top: 0;
  }

  section.section--bottom-padding-none {
    padding-bottom: 0;
  }

  section.section--no-padding.section--top-padding-short .section__content,
  section.section--top-padding-short:not(.section--no-padding) {
    padding-top: $short;
  }

  section.section--no-padding.section--top-padding-tall .section__content,
  section.section--top-padding-tall:not(.section--no-padding) {
    padding-top: $tall;
  }

  section.section--no-padding.section--top-padding-regular .section__content,
  section.section--top-padding-regular:not(.section--no-padding) {
    padding-top: $regular;
  }

  section.section--no-padding.section--bottom-padding-short .section__content,
  section.section--bottom-padding-short:not(.section--no-padding) {
    padding-bottom: $short;
  }

  section.section--no-padding.section--bottom-padding-tall .section__content,
  section.section--bottom-padding-tall:not(.section--no-padding) {
    padding-bottom: $tall;
  }

  section.section--no-padding.section--bottom-padding-regular .section__content,
  section.section--bottom-padding-regular:not(.section--no-padding) {
    padding-bottom: $regular;
  }

  section.section--height-screen {
    height: 100vh;
  }

  @media (min-width: $media-md) {
    section.section--inner-top-padding-standard .section__inner {
      padding-top: $short;
    }

    section.section--inner-bottom-padding-standard .section__inner {
      padding-bottom: $short;
    }

    section.section--inner-left-padding-standard .section__inner {
      padding-left: $short;
    }

    section.section--inner-right-padding-standard .section__inner {
      padding-right: $short;
    }

    section .section__inner .section__inner,
    section .section__inner .section__inner {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}

@include set-section-padding($spacing-l, $section-padding, $spacing-jumbo);

section {
  &.section--has-background-image {
    background-image: var(--background-image);
    background-size: var(--background-size, 100%);
    background-position: center center;

    &.section--background-style-tile {
      background-repeat: repeat;
      background-position: center top;
    }

    &.section--background-style-cover {
      background-size: cover;
    }

    &.section--background-style-fit {
      background-position: top left;
      background-repeat: no-repeat;
    }
  }

  .section__top,
  .section__bottom {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
  }

  .section__top {
    top: 0;
  }

  .section__bottom {
    bottom: 0;
  }

  &.section--has-border.section--border-full {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: var(--border-thickness, 1px);
    border-bottom-width: var(--border-thickness, 1px);

    &:last-child {
      border-bottom-width: 0px;
    }
  }

  &.section--has-border.section--border-container {
    .section__top .container {
      border-top-style: solid;
      border-top-width: var(--inner-border-thickness, 1px);
    }

    .section__bottom .container {
      border-bottom-style: solid;
      border-bottom-width: var(--inner-border-thickness, 1px);
    }

    &:last-child {
      .section__bottom .container {
        border-bottom-width: 0px;
      }
    }
  }

  .section__content.font-size-md-callout {
    font-weight: 300;
  }

  .section-header p {
    font-size: $font-size-extra-large;
    max-width: 1000px;
  }

  &.section--lead {
    h1,
    h2 {
      @extend .lead !optional;
    }
  }

  //Heading
  .section__heading {
    margin-top: 0px;

    &--lead  {
      @extend .lead !optional;
    }

    &--small {
      color: $dark-gray;
      font-family: $font-family-sans-serif;
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      line-height: 1.5;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    &--thin {
      color: $dark-gray;
      font-family: $font-family-sans-serif;
      font-size: $font-size-extra-large;
      font-weight: $font-weight-regular;
      line-height: 1.09;
      letter-spacing: 1.38px;
      text-transform: uppercase;
    }
  }

  .section__heading-wrap {
    display: flex;
    gap: $spacing-m;
  }

  .section__heading-wrap .buttons {
    margin-top: 10px;
  }

  //Components
  .component {
    position: relative;
    z-index: 1;
  }

  &:not(.section--no-padding)
    .section__content
    + .component:not(.component--componentCards) {
    margin-top: $spacing-xl;
  }

  .section__text picture + h2 {
    margin-top: 10px;
  }

  .section__text {
    max-width: 1000px;
  }
}

@media (min-width: $media-xl) {
  body section h2.section__heading.section__heading--lead {
    font-size: 42px;
  }
}

@media (min-width: $media-xxl) {
  .section__text {
    p > b:only-child,
    p > strong:only-child {
      font-size: $font-size-extra-large;
      line-height: 1.45;
      letter-spacing: 0.22px;
    }
  }
}

@media (max-width: $media-xxl-max) {
  @include set-section-padding($spacing-m, $spacing-l, $spacing-xxl);
}

@media (max-width: $media-md-max) {
  section .section__heading-wrap {
    flex-direction: column;
    gap: 0;
  }

  section .section__heading-wrap .buttons {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: $media-sm-max) {
  @include set-section-padding($spacing-s, $section-padding-mobile, $spacing-l);

  body section {
    &.section--has-background-image {
      background-image: var(--background-image-mobile);
    }

    &.section--background-color-mobile-warm-gray {
      background-color: $warm-gray;
    }

    &.section--background-color-mobile-white {
      background-color: $white;
    }

    &:not(.section--no-padding) .section__content + .component {
      margin-top: $spacing-m;
    }

    .section__heading--lead {
      font-size: $font-size-callout;
      line-height: 1.35;
    }

    &.section--has-border.section--border-container {
      .section__bottom .container,
      .section__top .container {
        max-width: calc(100% - 50px);
      }
    }
  }
}
