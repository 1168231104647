@import "../variables";
@import "../mixins";

@include set-page-gutter-attribute(".image-carousel__container", padding-left);
.image-carousel {
  position: relative;
  transition: opacity 1s;

  .image-carousel__image__wrapper {
    width: 100%;
    padding-top: var(--image-height);
    position: relative;
  }

  .image-carousel__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
