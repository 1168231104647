.section--country-partners {
  --gutter-width: 12px;

  .country-partners__wrap {
    margin-bottom: 48px;
  }

  .country-partners__partner {
    padding: 8px 16px;
    flex-wrap: nowrap;
  }

  .country-partners__partner-title,
  .country-partners__partner-country {
   font-size: 12px;
  }


  .country-partners__partner-title {
    margin-top: 5px;
    line-height: 140%;
  }

  .country-partners__partner-content {
    flex-grow: 1;
    align-self: baseline;
  }

  .country-partners__partner-icon {
    flex-grow: 0;
    align-self: center;
    display: block;
    padding-left: 12px;
  }

  .section__text p b {
    display: block;
    margin: 0 auto;
  }

  .section__text p {
    font-size: 20px;
  }

  .section__text p b {
    margin-top: 48px;
    display: block;
    font-size: 16px;
  }


  .country-partners__banner {
    margin: 24px 0;
  }

  @media (max-width: $media-sm-max) {
    .country-partners:not(.country-partners--expanded) .country-partners__partner:nth-child(1n+5) {
      display: none;
    }
  }

  @media (min-width: $media-md) {
    --grid-template: 1fr 1fr;

    .section__text {
      margin-top: 25px;
    }

    .section__text h2 {
      margin-top: 40px;
      font-size: 40px;
      font-weight: 300;
    }

    .section__text p {
      font-size: 24px;
    }

    .section__text p b {
      max-width: 640px;
    }

    .country-partners__banner {
      margin: 80px 0;
    }
  }

  @media (min-width: $media-lg) {
    --grid-template: 1fr 1fr 1fr;

    .country-partners__partner {
      padding: 16px;
    }

    .section__text h2 {
      margin-top: 55px;
      font-size: 48px;
    }

    .section__text p {
      font-size: 32px;
    }

    .section__text p b {
      max-width: 960px;
      font-size: 24px;
    }
  }

  @media (min-width: $media-xxl) {
    .section__text p b {
      max-width: 792px;
    }
  }
}