@import "../variables";
@import "../mixins";

.alert {
  font-size: 14px;
  display: block;
  position: relative;
  padding-left: 75px;
  padding-right: 75px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $cobalt;
  color: $white;
  text-decoration: none !important;
  transition: background-color linear 0.5s;
  .alert__icon {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    width: 12px;
  }

  &__content {
    display: flex;
    flex-wrap: nowrap;
  }

  &__title {
    padding-right: $spacing-xs;
  }

  &__title-icon {
    margin-left: $spacing-xxs;
  }

  &:hover {
    background-color: $malala;
  }
}

@media only screen and (max-width: 600px) {
  .alert {
    padding-left: 0px;
    padding-right: 0px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 600px) {
  body .alert__icon .alert__image {
    margin-left: 12px;
  }
}

@media only screen and (max-width: 1000px) {
  body .alert__icon .alert__image {
    margin-left: 12px;
  }
}

@media only screen and (max-width: 1000px) {
  .alert {
    padding-left: 0px;
    padding-right: 0px;
    line-height: 22px;
  }
}
