// Primary web colors.

// The l1 suffix is a lighter tint of the color, l2 is even lighter, etc.
// The d1 suffix is a darker shade of the color, d2 is even darker, etc.

$white: #fff;
$black: #05090b;

// Peace (i.e cobalt)
$peace: #233f96;
$peace-l1: #6579b6;
$peace-l2: #a7b2d5;
$peace-l3: #d9dff1;

// Malala (i.e navy)
$malala: #111e47;
$malala-l1: #1a2e6e;
$malala-li: $malala-l1;

// Rani (i.e orange)
$rani: #e46132;
$rani-d1: #b04a28;
$rani-d2: #74331b;

// Alma (i.e peach)
$alma: #f39473;
$alma-l1: #f8c1af;

// Zuhdya (i.e brown)\
$zuhdya: #d8b767;
$zuhdya-l1: #e4cd95;
$zuhdya-l2: #efe2c2;
$zuhdya-d1: #a0864c;
$zuhdya-d2: #685631;

// Najlaa (i.e teal)
$najlaa: #0b9d8c;
$najlaa-l1: #56c2b4;
$najlaa-l2: #9edcd4;
$najlaa-d1: #0a7b6d;
$najlaa-d2: #064f46;

// Zaynab (i.e purple)
$zaynab: #60427b;
$zaynab-l1: #907ba3;
$zaynab-l2: #bfb3ca;
$zaynab-d1: #47305a;
$zaynab-d2: #2e1f3a;

// Secondary web colors.

// Zia (i.e cool gray)
$zia: #3c3e3f;
$zia-l1: #797c7e;
$zia-l2: #b2b3b5;
$zia-l3: #cdcdce;
$zia-l4: #ebe9e8;
$zia-l5: #f5f3f2;

// Zia (i.e warm gray)
$luiza: #f5f3f2;
$luiza-d1: #ece8e7;
$luiza-d2: #d4cdca;
$luiza-d3: #c0b3b0;
$luiza-d4: #817c7a;
$luiza-d5: #413f3d;

// New brand colors
$navy: $malala;
$orange: $rani;
$peach: $alma;
$peach-light: #f7af96;
$pink: #c79fc9;
$purple: $zaynab;
$cobalt: $peace;
$cobalt-2: $peace-l1;
$cobalt-3: #a7b2d5;
$cobalt-4: #cbd3e9;
$cobalt-5: #e5ecff;
$blue: #1d4893;
$gold: $zuhdya;
$warm-gray: $luiza;
$cool-gray: $zia;
$dark-gray: $zia;
$emerald: $najlaa-d2;
$teal: $najlaa;
$seafoam: #aedcd4;
$sky: $peace-l3;
$yellow: #ffcb04;

$colors: (
  gray: $luiza,
  gray-1: $luiza-d1,
  white: $white,
  black: $black,
  blue: $blue,
  peace: $peace,
  peace-2: $peace-l2,
  peace-3: $peace-l3,
  malala: $malala,
  rani: $rani,
  alma: $alma,
  zuhdya: $zuhdya,
  najlaa: $najlaa,
  zaynab: $zaynab,
  zia: $zia,
  luiza: $luiza,
  navy: $navy,
  orange: $orange,
  peach: $peach,
  peach-2: $peach-light,
  purple: $purple,
  cobalt: $cobalt,
  cobalt-2: $cobalt-2,
  cobalt-5: $cobalt-5,
  gold: $gold,
  warm-gray: $warm-gray,
  seafoam: $seafoam,
  cool-gray: $cool-gray,
  emerald: $emerald,
  sky: $sky,
  teal: $teal,
);

:root {
  @each $color, $value in $colors {
    --#{color}: $value;
  }
}

$spacing-base: 6px;
$spacing-tiny: 8px;
$spacing-xxs: 12px;
$spacing-xs: 18px;
$spacing-s: 24px;
$spacing-m: 36px;
$spacing-l: 48px;
$spacing-xl: 60px;
$spacing-xxl: 78px;
$spacing-jumbo: 96px;
$spacing-mega: 150px;

$gutter-width: 24px;

$spacer: $spacing-base;

//Breakpoints
$media-xs: 375px;
$media-sm: 500px;
$media-md: 659px;
$media-lg: 992px;
$media-xl: 1281px;
$media-xxl: 1421px;
$media-xxxl: 1562px;

//Container widths
$container-width-lg: 900px;
$container-width-xl: 1100px;
$container-width-xxl: 1200px;
$container-width-xxxl: 1380px;

$media-xxs-max: 350px;
$media-xs-max: $media-sm - 1;
$media-sm-max: $media-md - 1;
$media-md-max: $media-lg - 1;
$media-lg-max: $media-xl - 1;
$media-xl-max: $media-xxl - 1;
$media-xxl-max: $media-xxxl - 1;

$container-padding-sm: $spacing-s;
$container-padding-mobile: $container-padding-sm;
$container-padding-md: $spacing-m;
$container-padding-tablet: $container-padding-md;
$container-padding: $spacing-xl;

$font-size-base: 16px;
$line-height-base: 1.5;
$letter-spacing-base: 0.2px;

$font-size-h1: 42px;
$font-size-h2: 32px;
$font-size-h3: 24px;
$font-size-h4: 16px;
$font-size-h5: 12px;
$font-size-h6: 12px;
$font-size-h7: 12px;

//Multiply font sizes
$text-display-sizes: (
  1: 1.1,
  2: 1.2,
  3: 1.3,
  4: 1.4,
  5: 1.5,
);

$breakpoints: (
  xs: $media-xs,
  sm: $media-sm,
  md: $media-md,
  lg: $media-lg,
  xl: $media-xl,
  xxl: $media-xxl,
  xxxl: $media-xxxl,
);

$max-breakpoints: (
  xs: $media-xs-max,
  sm: $media-sm-max,
  md: $media-md-max,
  lg: $media-lg-max,
  xl: $media-xl-max,
  xxl: $media-xxl-max,
);

$border-width-base: 2px;
$border-radius-base: 4px;

$spacers: (
  0: 0,
  1: $spacing-tiny,
  2: $spacing-xxs,
  3: $spacing-xs,
  4: $spacing-s,
  5: $spacing-m,
  6: $spacing-l,
  7: $spacing-xl,
  8: $spacing-xxl,
  9: $spacing-jumbo,
  10: $spacing-mega,
);

$section-padding: $spacing-xxl;
$section-padding-mobile: $spacing-l;

$z-index: (
  modal: 900,
  navigation-flyout: 801,
  navigation: 800,
  header: 700,
  hero-overlay: 650,
  hero-signature: 603,
  hero-heading: 602,
  hero-accents: 601,
  carousel: 2,
  carousel-base: 1,
);

$font-sizes: (
  0: 11px,
  1: 13px,
  2: 14px,
  3: 15px,
  4: 16px,
  5: 18px,
  6: 20px,
  7: 22px,
  8: 24px,
  9: 26px,
  10: 28px,
);

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;

$font-family-sans-serif: "lato", "cairo", Geneva, Tahoma, sans-serif;
$font-family-serif: "chiavettieri", "Times New Roman", serif;
$font-family-base: $font-family-serif;

$font-size-tiny: map_get($font-sizes, 0);
$font-size-small: map_get($font-sizes, 1);
$font-size-secondary: map_get($font-sizes, 2);
$font-size: map_get($font-sizes, 4);
$font-size-medium: map_get($font-sizes, 5);
$font-size-large: map_get($font-sizes, 6);
$font-size-extra-large: map_get($font-sizes, 7);
$font-size-extra-extra-large: map_get($font-sizes, 8);
$font-size-callout: 32px;
$font-size-display: 42px;
$font-size-jumbo: 50px;
$font-size-stat: 72px;

$btn-bg-color: $peace;
$btn-outline-color: $malala;
$btn-padding-y: 16px;
$btn-padding-x: 24px;
$btn-border-radius: $border-radius-base;
$btn-border-width: $border-width-base;
$btn-font-size: $font-size-tiny;
$btn-letter-spacing: 1.36px;
$btn-font-weight: bold;
$btn-line-height: 16px;

$input-color: $zia;
$input-padding-y: 14px;
$input-padding-x: 14px;
$input-border-radius: $border-radius-base;
$input-border-width: 1px;
$input-font-size: $font-size-base;
$input-letter-spacing: 1.36px;
$input-font-weight: $font-weight-bold;
$input-line-height: $font-size-base;

$header-height: 100px;
$mobile-header-height: 66px;

$page-gutter-width-sm: $container-padding-mobile;
$page-gutter-width-md: $container-padding-tablet;
$page-gutter-width-lg: calc(
  ((100vw - #{$container-width-lg}) / 2) - #{$spacing-tiny}
);
$page-gutter-width-xl: calc(
  ((100vw - #{$container-width-xl}) / 2) - #{$spacing-tiny}
);
$page-gutter-width-xxl: calc(
  ((100vw - #{$container-width-xxl}) / 2) - #{$spacing-tiny}
);
$page-gutter-width: calc(
  ((100vw - #{$container-width-xxxl}) / 2) - #{$spacing-tiny}
);

$shadow: 3px 3px 3px -1px rgba($navy, 0.15);

:root {
  --webkit-focus-ring-color: #{$cobalt};
  --swiper-pagination-color: #111e47;
  --plyr-color-main: #{$peach};
}
