/****************************
 * Flex Helpers
 *****************************

 * Provides flex related helper classes
 *
 * Breakpoint: xl, lg, md, sm, xl (see _variables.scss)
 *
 * Examples:
 * flex-column-xl
 * flex-column
 * flex-justify-md-end
 */

@mixin makeFlexHelpers($breakpoint: null) {
  @if $breakpoint {
    $breakpoint: "-#{$breakpoint}";
  }

  .flex#{$breakpoint} {
    display: flex;
    flex-wrap: wrap;
  }

  .flex#{$breakpoint}-row,
  .flex-row#{$breakpoint} {
    flex-direction: row;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-no-wrap {
    flex-wrap: nowrap;
  }

  .flex#{$breakpoint}-column,
  .flex-column#{$breakpoint} {
    flex-direction: column;
  }

  .flex-justify#{$breakpoint}-center {
    justify-content: center;
  }

  .flex-justify#{$breakpoint}-start {
    justify-content: flex-start;
  }

  .flex-justify#{$breakpoint}-end {
    justify-content: flex-end;
  }

  .flex-justify#{$breakpoint}-space-between {
    justify-content: space-between;
  }

  .flex-justify#{$breakpoint}-space-around {
    justify-content: space-around;
  }

  .flex-align-items-center {
    align-items: center;
  }

  .flex-justify-items-center {
    justify-items: center;
  }


  .flex-align-self-center {
    align-self: center;
  }


  .flex-align-self-start {
    align-self: flex-start;
  }


  .flex-align-self-end {
    align-self: flex-end;
  }

  .flex-stretch#{$breakpoint} {
    align-content: stretch;
    align-items: stretch;
  }

  .flex-grow#{$breakpoint}-1,
  .flex-grow#{$breakpoint} {
    flex-grow: 1;
  }

  .flex-grow#{$breakpoint}-0 {
    flex-grow: 0;
  }

  .flex-align#{$breakpoint}-center {
    align-items: center;
  }

  .flex-align#{$breakpoint}-start {
    align-items: flex-start;
    -ms-flex-align: start;
  }

  .flex#{$breakpoint}-0 {
    flex: 0;
  }

  .flex#{$breakpoint}-1 {
    flex: 1;
  }

  .flex#{$breakpoint}-2 {
    flex: 2;
  }

  .flex#{$breakpoint}-3 {
    flex: 3;
  }

  .flex#{$breakpoint}-4 {
    flex: 4;
  }

  .flex#{$breakpoint}-5 {
    flex: 5;
  }

  .flex#{$breakpoint}-auto {
    flex: auto;
  }

  .flex#{$breakpoint}-column-reverse,
  .flex#{$breakpoint}-reverse {
    flex-direction: column-reverse;
  }

  .flex#{$breakpoint}-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-direction#{$breakpoint}-initial {
    flex-direction: initial;
  }

  .flex-stretch {
    align-items: stretch;
    align-content: stretch;
  }

}


@include makeFlexHelpers;

//Make the responsive breakpoints
@each $breakpoint, $value in $breakpoints {
  @media (min-width: $value) {
    @include makeFlexHelpers($breakpoint);
  }
}