@import "../variables";
@import "../mixins";

$covid-border: solid 2px $emerald;
$covid-spacing: $spacing-m;
$covid-accent-offset: 200px;
$covid-accent-offset-mobile: 50px;

.covid-hero {
  .hero__overlay-inner {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  .hero__overlay__wrapper {
    overflow: hidden;

    .hero__lead {
      padding: 0;
      background-color: $emerald;

      &:before,
      &:after {
        position: absolute;
        content: "";
        background-size: 100% 100%;
        top: 0;
        pointer-events: none;
      }
    }

    .hero__lead__content {
      @include text() {
        color: $white;
      }
    }

    h3 {
      font-size: $font-size-extra-extra-large;
      font-weight: normal;
      line-height: 1.33;
      letter-spacing: 0.19px;
    }

    p {
      font-size: $font-size-medium;
      line-height: 1.33;
      letter-spacing: 0.14px;
    }

    p a:only-child {
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.7px;
      font-size: $font-size-secondary;
      color: $white;
      max-width: 352px;

      &:hover {
        color: $white;
        text-decoration: underline;
      }
    }

    .give-lively-wrap {
      h3 {
        margin-top: 0px;
        color: $navy;
        font-size: $font-size-callout;
        font-weight: $font-weight-light;
        line-height: 1.25;
        letter-spacing: 0.2px;
        text-align: center;
      }
    }
  }

  .hero__lead__secondary__content__body {
    min-height: 228px;
  }
}

@media (min-width: $media-lg) {
  .covid-hero {
    .hero__overlay {
      bottom: -$covid-accent-offset * 0.5;
    }

    .hero__overlay__wrapper {
      padding-top: $covid-accent-offset;
    }

    .hero__lead {
      &:before {
        background-image: url("~images/accents/group-1.svg");
        width: 305px;
        height: 286px;
        left: 0;
        transform: translate(-50%, -50%);
      }

      &:after {
        background-image: url("~images/accents/group-2.svg");
        width: 539px;
        height: 347px;
        right: 0;
        transform: translate(34%, -36%);
        z-index: -1;
      }
    }

    .give-lively-wrap h3 {
      display: none;
    }

    .give-lively-wrap + p {
      display: none;
    }

    .hero__lead__secondary__content {
      margin-left: 0;
      margin-right: 0;
      flex-basis: 50%;
    }

    .hero__lead__secondary__content__body {
      padding: $spacing-m $spacing-s $spacing-s $spacing-s;
    }

    .hero__lead__content {
      padding: 63px 67px 40px 83px;
    }

    .hero__lead__secondary__content {
      background-color: $white;
      display: flex;
      align-items: center;
    }

    .hero__lead__secondary__content__body {
      width: 100%;
    }

    #give-lively-widget .gl-widget__suggested-amounts-container {
      display: none;
    }
  }
}

@media (max-width: $media-xxl-max) {
  .covid-hero {
    .hero__overlay__wrapper h3 {
      font-size: $font-size-extra-large;
    }
  }
}

@media (max-width: $media-md-max) {
  .covid-hero {
    .hero__overlay {
      bottom: -$covid-accent-offset-mobile * 0.5;
    }

    .hero__overlay__wrapper {
      padding-top: $covid-accent-offset-mobile;
    }

    .hero__lead {
      padding: 0 $container-padding-mobile;
    }

    .hero__lead__secondary__content__body {
      @include text() {
        color: $white;
      }

      p {
        font-size: $font-size;
      }
    }

    .give-lively-wrap {
      background-color: $white;
      padding: $container-padding-mobile;
      margin-bottom: $spacing-base;
      margin-left: -$container-padding-mobile;
      margin-right: -$container-padding-mobile;
    }

    .hero__lead__secondary__content,
    .hero__lead__content {
      padding: $spacing-tiny $container-padding-mobile;
    }

    .hero__overlay__wrapper {
      h3 {
        margin-top: $spacing-xs;
        margin-bottom: $spacing-xs;
        font-size: $font-size-extra-large;
        line-height: 1.27;
        letter-spacing: 0.17px;
      }

      p {
        margin-top: $spacing-xs;
        margin-bottom: $spacing-xs;
        font-size: $font-size-medium;
        line-height: 1.22;
        letter-spacing: 0.14px;
      }

      .give-lively-wrap + p {
        font-size: 16px;
      }

      .hero__lead {
        &:before {
          background-image: url("~images/accents/group-3.svg");
          width: 179px;
          height: 165px;
          left: 0;
          transform: translate(-66%, -55%);
          z-index: 1;
        }
      }
    }
  }
}

@media (max-width: $media-xxs-max) {
  .covid-hero {
    .container {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
