section.section--press-contact {
  overflow: hidden;
  .accent-images {
    position: relative;

    .image {
      height: auto;
      max-width: 100%;
    }

    .accent-image {
      position: relative;
      z-index: 1;
      max-width: 40%;
    }

    .accent-image img {
      display: block;
    }
    
    .accent-image:nth-child(2) {
      position: absolute;
      bottom: 0;
      right: 50%;
      transform: translate(50%, 60%);
      max-width: 40%;
      z-index: 2;
    }

    .accent-image:nth-child(3) {
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateY(50%);
      max-width: 40%;
      z-index: 2;
    }
  }
}