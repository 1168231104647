@import "../variables";
@import "../mixins";

.scroll-button {
  --translate-x: calc(100% + 35px);
  --translate-y: 200%;
  --scale: 1;
  --right-offset: 0px;
  --right: calc(var(--page-gutter-width, 25px) + var(--right-offset, 0px));
  bottom: 25px;
  right: var(--right);
  transform: translate(var(--translate-x), var(--translate-y))
    scale(var(--scale));

  &:hover {
    opacity: 0.9;
  }
}

@media (max-width: 1700px) {
  .scroll-button {
    --right-offset: 50px;
    --scale: 0.8;
  }
}

@media (max-width: 1600px) {
  .scroll-button {
    ---right-offset: 0;
    --scale: 0.7;
  }
}

@media (max-width: 1500px) {
  .scroll-button {
    --scale: 0.6;
    --right-offset: 60px;
  }
}

@media (max-width: 1279px) {
  .scroll-button {
    --scale: 0.7;
    --right-offset: 25px;
  }
}

@media (max-width: 1150px) {
  .scroll-button {
    --right-offset: 50px;
  }
}

@media (max-width: 1095px) {
  .scroll-button {
    --right: 10px;
    --translate-x: 0;
  }
}

@media (max-width: $media-md) {
  .scroll-button {
    --scale: 1;
  }
}

.scroll-button--visible {
  --translate-y: 0;
}
