@import "../variables";
@import "../mixins";

$gallery-bottom-margin: 86px;
$gallery-card-border-top: 8px;
$gallery-card-border-right: -16px;
$gallery-card-border-bottom: 16px;
$gallery-card-border-left: 18px;
$gallery-card-padding-y: 36px;
$gallery-card-padding-left: 36px;
$gallery-indent: 100px;
$gallery-accent-width: 380px;
$gallery-accent-padding-bottom: 50px;
$gallery-accent-padding-top: 150px;
$gallery-accent-content-padding-top: 175px;
$gallery-accent-card-left: -10px;
$gallery-accent-overlap: 125px;
$gallery-indent-mobile: 45px;
$gallery-card-top-margin-mobile: -165px;

@mixin resize_gallery($divisor: 1, $multiplier: 1) {
  //FLIPBOOK
  .gallery-flipbook--has-accents {
    .gallery-flipbook__gallery {
      width: calc(
        50% + #{$gallery-accent-width * $divisor} - #{$gallery-indent}
      );
    }

    .gallery-flipbook__content {
      width: calc(
        50% - #{$gallery-accent-width * $divisor} + #{$gallery-indent}
      );
    }
  }

  body .gallery__wrapper {
    .gallery__navigation {
      right: $gutter-width * $divisor;
    }

    .gallery__image__container {
      width: 100%;
      margin-bottom: $gallery-bottom-margin * $multiplier;
    }
  }

  //NO ACCENTS OR IN A FLIPBOOK WITH NO ACCENTS
  body .gallery:not(.gallery--has-accents) .gallery-slide,
  body .gallery__wrapper .gallery__slide:not(.gallery__slide--has-accent) {
    .gallery__card {
      left: -$gallery-indent + $gallery-card-border-left;
    }
  }

  //WITH ACCENTS OR IN A FLIPBOOK WITH ACCENTS

  body .gallery__wrapper.gallery--has-accents {
    .gallery__navigation {
      top: calc(var(--galleryImageHeight) + 150px * #{$divisor});
    }
  }

  body .gallery-flipbook--has-accents .gallery-slide,
  body .gallery__wrapper .gallery__slide--has-accent {
    .gallery__accent__container {
      width: $gallery-accent-width * $divisor;
      margin-right: -$gallery-accent-overlap * $divisor;
    }
  }
}

//ALL SIZES

body .gallery__wrapper {
  position: relative;

  .carousel,
  .carousel__container {
    width: 100%;
  }

  .gallery__image__container {
    width: 100%;
    padding-top: 76%;
    position: relative;
    max-height: 486px;
  }

  .gallery__image {
    @include position-fit;
    object-fit: cover;
  }

  .gallery__card {
    z-index: 1;

    .card__body {
      background-color: white;
    }
  }

  .gallery__navigation {
    display: flex;
    align-items: center;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: relative;
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
    width: 50px;
    height: 50px;
    margin-top: 0px;

    &.swiper-button-disabled {
      display: block;
      visibility: hidden;
    }
  }

  .swiper-count {
    padding-left: 10px;
    padding-right: 10px;
  }

  .gallery__accent__image {
    position: absolute;
    top: $gallery-accent-padding-top * 0.5;
    right: 0;
    bottom: $gallery-card-border-bottom;
    left: 0;
    width: 100%;
    height: 80%;
    object-fit: contain;
  }

  .gallery__card__backdrop {
    width: calc(var(--cardWidth, 0) + #{$gallery-card-border-right});
    height: calc(
      var(--cardHeight, 0) + #{$gallery-card-border-bottom +
        $gallery-card-border-top}
    );
    background-color: $peace-l2;
    z-index: -1;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .gallery__slide--accent-color-teal .gallery__card__backdrop {
    background-color: #9edcd4;
  }

  .gallery__slide--accent-color-orange .gallery__card__backdrop {
    background-color: $orange;
  }

  .gallery__slide--accent-color-navy .gallery__card__backdrop {
    background-color: $navy;
  }

  .gallery__slide--accent-color-gold .gallery__card__backdrop {
    background-color: $gold;
  }

  .gallery__slide--accent-color-peach .gallery__card__backdrop {
    background-color: $peach-light;
  }

  .gallery__card .card__body {
    padding-top: $gallery-card-padding-y;
    padding-left: $gallery-card-padding-left;
    padding-bottom: $gallery-card-padding-y;
  }

  .gallery__card .card__content {
    padding-top: 0px;

    h3 {
      font-size: $font-size-large;
      font-family: $font-family-serif;
      letter-spacing: 0.22px;
      font-weight: $font-weight-bold;
      margin-bottom: 10px;
    }

    blockquote {
      font-family: $font-family-sans-serif;
      font-size: $font-size-medium;
      font-weight: $font-weight-light;
      line-height: 1.28;
      letter-spacing: normal;
      color: $dark-gray;
    }

    blockquote + p {
      line-height: 1.28;
    }
  }
}

// TABLET - DESKTOP

@media (min-width: $media-md) {
  @include resize_gallery();

  //FLIPBOOK

  .gallery-flipbook {
    .gallery-flipbook__content__inner {
      transform: translateY(-$gallery-bottom-margin * 0.5);
    }

    //NO ACCENTS

    &:not(.gallery-flipbook--has-accents) {
      .gallery-flipbook__gallery {
        width: calc(50% + #{$gallery-indent});
      }

      .gallery-flipbook__content {
        width: calc(50% - #{$gallery-indent});
      }

      .gallery__accent__container {
        min-width: $gallery-indent;
      }

      .gallery-flipbook__content__inner {
        padding-right: $gallery-indent;
      }
    }
  }

  //GALLERY

  body .gallery__wrapper {
    .gallery__navigation {
      position: absolute;
      right: $gutter-width;
      width: 200px;
      z-index: 3;
      display: flex;
      top: calc(var(--galleryImageHeight));
      transform: translateY(50%);
    }
    .gallery__card__backdrop {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .gallery__card {
      position: absolute;
      left: $gallery-card-border-left;
      bottom: $gallery-card-border-bottom;
      min-width: unset;
      max-width: unset;
      width: 410px;
    }

    .gallery__content__container {
      position: relative;
      flex-grow: 1;
      display: flex;
      align-items: flex-end;
    }
  }

  .gallery--has-accents {
  }

  //NO ACCENTS OR IN A FLIPBOOK WITH NO ACCENTS
  body .gallery:not(.gallery--has-accents) .gallery-slide,
  body .gallery__wrapper .gallery__slide:not(.gallery__slide--has-accent) {
    .gallery__card {
      left: -$gallery-indent + $gallery-card-border-left;
    }

    .gallery__card__backdrop {
      left: -$gallery-indent;
    }
  }

  //WITH ACCENTS OR IN A FLIPBOOK WITH ACCENTS
  body .gallery--has-accents .gallery-slide,
  body .gallery__wrapper .gallery__slide--has-accent {
    .gallery__content__container {
      padding-top: $gallery-accent-padding-top;
    }

    .gallery__accent__container {
      position: relative;
      z-index: 1;
      padding-top: $gallery-accent-padding-top;
    }

    .gallery__accent__image {
      object-position: top !important;
    }

    .gallery__card {
      width: 350px;
      left: $gallery-accent-card-left;
    }

    .gallery__card__backdrop {
      left: $gallery-accent-card-left - $gallery-card-border-left;
    }
  }
}

@media (min-width: $media-lg) {
  body .gallery__wrapper .swiper-pagination {
    display: none;
  }
}

//TABLET - LAPTOPS
@media (min-width: $media-lg) and (max-width: $media-xxl-max) {
  @include resize_gallery(0.75, 1.75);

  body .gallery-flipbook__content {
    line-height: 1.4;
  }

  body .gallery__wrapper > .gallery__navigation {
    justify-content: flex-end;
    right: 0 !important;
  }

  body
    .gallery-flipbook:not(.gallery-flipbook--has-accents)
    .gallery-flipbook__content__inner {
    padding-right: 50px;
  }

  body .gallery--has-accents .gallery-slide,
  body .gallery__wrapper .gallery__slide--has-accent {
    .gallery__accent__image {
      top: 22px;
    }

    .gallery__accent__container {
      padding-top: $gutter-width;
    }

    .gallery__content__container {
      padding-top: 90px;
    }

    .gallery__accent__container {
      padding-top: $gutter-width;
    }

    .gallery__card {
      width: 300px;
    }
  }
}

//Small desktops and laptops
@media (min-width: $media-lg) and (max-width: $media-xl-max) {
  @include resize_gallery(0.65, 1.65);
}

//Tablet and mobile
@media (max-width: $media-md-max) {
  body .gallery-flipbook {
    .gallery__navigation {
      display: none;
    }
  }
}
//Tablet
@media (min-width: $media-md) and (max-width: $media-md-max) {
  .gallery-flipbook__gallery,
  .gallery__wrapper,
  .gallery-flipbook__gallery,
  .gallery-flipbook:not(.gallery--has-accents) .gallery-flipbook__content,
  .gallery-flipbook--has-accents .gallery-flipbook__content,
  .gallery-flipbook .gallery-flipbook__content__inner {
    width: auto;
    min-width: 100%;
    transform: none;
    padding-right: 0px;
    padding-left: 0px;
    box-sizing: border-box;
  }

  .gallery {
    .swiper-wrapper {
      padding-bottom: 75px;
    }
  }

  .gallery-flipbook:not(.gallery--has-accents) .gallery-flipbook__content,
  .gallery-flipbook--has-accents .gallery-flipbook__content {
    padding-right: $page-gutter-width-md;
    padding-left: $page-gutter-width-md;
  }

  body .gallery--has-accents .gallery-slide .gallery__content__container,
  body
    .gallery:not(.gallery--has-accents)
    .gallery-slide
    .gallery__content__container,
  body
    .gallery__wrapper
    .gallery__slide:not(.gallery__slide--has-accent)
    .gallery__content__container,
  body
    .gallery__wrapper
    .gallery__slide--has-accent
    .gallery__content__container {
    padding-top: $spacing-l;
    width: 100%;
  }

  body
    .gallery:not(.gallery--has-accents)
    .gallery-slide
    .gallery__content__container,
  body
    .gallery__wrapper
    .gallery__slide:not(.gallery__slide--has-accent)
    .gallery__content__container {
    padding-right: $spacing-m;
  }

  body .gallery:not(.gallery--has-accents) .gallery-slide {
    padding-right: $page-gutter-width-md;
  }

  body .gallery__wrapper.gallery--has-accents .gallery__navigation,
  body .gallery__wrapper .gallery__navigation {
    justify-content: flex-end;
    top: calc(var(--galleryImageHeight));
    transform: translateY(150%);
  }
}

//MOBILE
@media (max-width: $media-sm-max) {
  body .gallery-flipbook {
    .gallery__navigation {
      display: none;
    }

    .gallery-flipbook__content__inner {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  body .gallery-flipbook__gallery {
    display: flex;
    justify-content: center;
  }

  body .gallery__wrapper {
    .gallery__accent_container {
      display: none;
    }
    .gallery__slide__inner {
      position: relative;
    }

    .gallery__image__container {
      padding-top: 100%;
    }

    .gallery__card {
      position: relative;
      margin-top: $gallery-card-top-margin-mobile;
      z-index: 1;
      width: calc(100% - #{$gallery-indent-mobile});
      margin-left: $gallery-indent-mobile;
    }

    .gallery__card__backdrop {
      z-index: 1;
      left: $gallery-indent-mobile - $gallery-card-border-left;
      bottom: -$gallery-card-border-bottom;
      height: calc(
        var(--cardHeight, 0) - #{$gallery-card-padding-y +
          $gallery-card-border-top}
      );
      right: $gallery-card-border-left;
      width: auto;
    }

    .gallery__slide__inner {
      padding-bottom: calc(var(--galleryCardHeight, 100px) / 2 - 100px);
    }

    .gallery__accent {
      display: none;
    }

    .swiper-container {
      margin-top: 75px;
      padding-bottom: 75px;
    }
  }

  .gallery-flipbook {
    .gallery-flipbook__gallery {
      padding-left: 0px;
    }

    .gallery-flipbook__content__inner {
      padding-left: $page-gutter-width-sm;
      padding-right: $page-gutter-width-sm;
    }
  }

  body .gallery-flipbook {
    .gallery-flipbook__content {
      @include text {
        &:first-child {
          margin-top: 0px;
        }
      }
      padding-left: $page-gutter-width-md;
      padding-right: $page-gutter-width-md;
      padding-bottom: $spacing-s;
    }
  }

  body .gallery-flipbook__gallery {
    .gallery__wrapper {
      max-width: 100%;
    }

    .swiper-container {
      margin-top: 0;
    }
  }

  body .gallery-flipbook {
    .gallery-flipbook__content {
      padding-left: $page-gutter-width-sm;
      padding-right: $page-gutter-width-sm;
    }
  }

  body .gallery-flipbook__gallery {
    padding-left: 0px;
    padding-right: 0px;
  }

  body .gallery__wrapper {
    .gallery__slide__inner {
      justify-content: center;
    }

    .gallery__card,
    .gallery__image__container,
    .gallery__content__container {
      min-width: 100%;
      width: 100%;
      margin-top: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }

    .gallery__image__container {
      margin-left: $page-gutter-width-sm;
    }

    .gallery__card {
      width: auto;
      min-width: auto;
      max-width: unset;
      margin-top: -90px;
      margin-right: 0px;
      margin-left: $spacing-xl;
    }

    .gallery__card__backdrop {
      margin-left: $gallery-card-border-left;
    }
  }
}
