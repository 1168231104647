@import "variables";
@import "mixins";

.plyr__poster {
  background-size: 100%;
}

.plyr__video-embed {
  position: relative;
}
