@import "../variables";
@import "../mixins";

nav.tabs {
  text-align: center;

  .nav-item {
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    padding: 0 map_get($spacers, 3);
    font-size: 11px;
    color: $black;
    letter-spacing: 0.08em;
    position: relative;

    &:before {
      @include position-left;
      display: block;
      content: " ";
      background-color: $black;
      height: 10px;
      width: 1px;
    }

    &:first-child {
      &:before {
        display: none;
      }
    }

    &.active {
      color: $peace-l2;
    }
  }
}

@media (max-width: $media-md-max) {
  nav.tabs {
    .nav-item {
      background-color: $luiza-d1;
      border-radius: 4px;
      font-size: 9px;
      padding: map_get($spacers, 1) map_get($spacers, 2);
      margin: map_get($spacers, 1);
      &:before {
        display: none;
      }

      &:hover,
      &.active {
        background-color: $peace-l2;
        color: $white;
      }
    }
  }
}
