.visible {
  opacity: 1;
}

.invisible {
  opacity: 0;
}

.visibility-visible {
  visibility: visible;
}

.hidden,
.visibility-hidden {
  visibility: hidden;
}