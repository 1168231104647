@import "../variables";
@import "../mixins";

.image-flipbook {
  margin-bottom: var(--flipbook-image-y, 0);

  .image-flipbook__image_container {
    display: flex;
    align-items: stretch;
  }

  .image--flipbook__image-wrapper {
    width: 100%;

    .image-flipbook__image {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transform: translateY(var(--flipbook-image-y, 0));

      &--contain {
        object-fit: contain;
      }
    }
  }

  &--has-images {
    @media (min-width: $media-md) {
      margin-bottom: 25px;
    }

    .image-flipbook__text {
      padding-top: 0;
    }
  }

  .image--flipbook__images-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: $media-md) {
      picture .image-flipbook__image {
        margin-top: -50px;
      }

      picture:nth-child(2) .image-flipbook__image {
        margin-top: -150px;
      }

      picture .image-flipbook__image {
        max-width: 55%;
      }

      picture:nth-child(3) .image-flipbook__image {
        max-width: 60%;
      }

      picture:nth-child(3) .image-flipbook__image.image--landscape {
        max-width: 65%;
      }

      picture:first-child .image-flipbook__image {
        max-width: 75%;
        margin-top: -15px;
      }
    }

    @media (max-width: $media-sm-max) {
      picture .image-flipbook__image {
        max-width: 80%;
        margin-top: -25px;
      }
    }

    picture:nth-child(3) .image-flipbook__image {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &:not(.flex-lg-row-reverse) {
    @media (min-width: $media-lg) {
      .image-flipbook__image--padding-large {
        padding-right: 100px;
      }

      .image-flipbook__image--padding-medium {
        padding-right: $spacing-l;
      }

      .image-flipbook__image--padding-small {
        padding-right: $spacing-m;
      }
    }

    .image--flipbook__images-wrapper {
      picture:nth-child(odd) .image-flipbook__image {
        margin-right: auto;
      }

      picture:nth-child(even) .image-flipbook__image {
        margin-left: auto;
      }
    }
  }

  &.flex-lg-row-reverse {
    @media (min-width: $media-lg) {
      .image-flipbook__image--padding-large {
        padding-left: 100px;
      }

      .image-flipbook__image--padding-medium {
        padding-left: $spacing-l;
      }

      .image-flipbook__image--padding-small {
        padding-left: $spacing-m;
      }
    }

    .image--flipbook__images-wrapper {
      picture:nth-child(odd) .image-flipbook__image {
        margin-left: auto;
      }

      picture:nth-child(even) .image-flipbook__image {
        margin-right: auto;
      }
    }
  }

  .image--flipbook--has-images .image-flipbook__image_container {
    position: relative;
  }

  .buttons {
    margin-top: $spacing-m;
  }
}

.container .image-flipbook .flipbook__text_container:last-child .image-flipbook__text,
.container .image-flipbook .flipbook__text_container:first-child.image-flipbook__text, .image-flipbook__text {
  height: 100%;
  padding: $spacing-xxl;
  box-sizing: border-box;

  @include text {
    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &--padding-medium {
    padding: $spacing-xl;
  }

  &--padding-small {
    padding: $spacing-l;
  }

  &--padding-none {
    padding: 0 $spacing-l;
  }
}

@media (max-width: $media-md-max) {
  body .image-flipbook__image-container,
  body .image-flipbook__content-container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .container .image-flipbook:not(.image-flipbook--no-image) .flipbook__text_container:last-child .image-flipbook__text,
  .container .image-flipbook:not(.image-flipbook--no-image) .flipbook__text_container:first-child .image-flipbook__text, .image-flipbook:not(.image-flipbook--no-image) {
    padding: $container-padding-tablet;
  }

  body .image-flipbook.image-flipbook--no-image .image-flipbook__text {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .image-flipbook__image--padding-large {
    padding-right: 60px;
    padding-left: 20px;
  }

  .image-flipbook__image--padding-medium {
    padding-right: $spacing-l;
    padding-left: 20px;
  }

  .image-flipbook__image--padding-small {
    padding-right: $spacing-m;
    padding-left: 20px;
  }

  .image-flipbook .image--flipbook__image-wrapper .image-flipbook__image {
  }
}

@media (max-width: $media-md-max) {
  body .image-flipbook__content {
    padding-left: 0px;
    padding-right: 0px;
  }
  .container .image-flipbook .flipbook__text_container:last-child .image-flipbook__text,
  .container .image-flipbook .flipbook__text_container:first-child .iamge-flipbook__text, .image-flipbook__text {
    padding: $container-padding-mobile;
  }
}
