@import "../variables";
@import "../mixins";

$imageHeight: 600px;
$imageWidth: 498px;

.bio-hero__wrapper {
  overflow: hidden;
}
.bio-hero {

  .bio-hero__top {
    .caption {
      font-weight: $font-weight-black;
    }
  }

  blockquote.quote {
    font-weight: $font-weight-light;
    font-family: $font-family-sans-serif;
    line-height: 1.2;
    letter-spacing: normal;
  }

  .bio-hero__footer {
    position: absolute;
    right: 0;
    left: 0;
    transform: translateY(100%);
  }

  .accent {
    position: absolute;
    z-index: 0;
  }

  .bio-hero__image {
    img {
      width: 100%;
    }
  }

  h1 {
    font-size: 32px;
  }
}

@media (max-width: $media-sm-max) {
  .bio-hero {
    padding-top: 0px !important;
    position: relative;
    margin-bottom: $spacing-jumbo;

    .bio-hero__image {
      margin-left: -$container-padding-md;
      margin-right: -$container-padding-md;
      img {
        width: 100%;
      }
      margin-bottom: $spacing-xxs;
    }

    .bio-hero__content {
      position: relative;
    }

    .bio-hero__footer {
      bottom: 0;
    }

    blockquote.quote {
      font-size: $font-size-callout;
      margin-bottom: $spacing-xxl - $section-padding-mobile;
    }

    .caption {
      font-size: $font-size-base;
      font-weight: $font-weight-black;
    }

    .accent--large {
      top: 25px;
      right: -40px;
      width: 90px;
      height: 110px;
    }

    .accent--small {
      display: none;
    }

    .pattern-bar {
      margin-left: -$container-padding-md;
      margin-right: -$container-padding-md;
    }
  }
}

@media (min-width: $media-md) {
  .bio-hero {
    min-height: 440px;
    box-sizing: border-box;
    margin-bottom: $spacing-jumbo;

    .container {
      position: relative;
    }

    .bio-hero__image {
      width: $imageWidth - 160px;
      min-width: $imageWidth - 160px;
      position: relative;
      margin-bottom: -$section-padding - 120px;
      margin-left: -35px;
    }

    .bio-hero__footer {
      bottom: -$section-padding;
    }

    .bio-hero__content__container {
      margin-left: $section-padding * 0.5;
      position: relative;
    }

    .caption {
      font-size: $font-size-secondary;
      letter-spacing: 1.5px;
    }

    blockquote.quote {
      font-size: $font-size-callout;
    }

    .accent--large {
      top: 15%;
      right: -80px;
    }

    .accent--small {
      top: 10%;
      right: 220px;
    }

    .pattern-bar {
      margin-right: -$page-gutter-width-md;
    }
  }
}

@media (min-width: $media-lg) {
  .bio-hero {
    .bio-hero__image {
      width: $imageWidth - 200px;
      min-width: $imageWidth - 200px;
    }

    .bio-hero__content__container {
      margin-left: $section-padding;
    }

    .pattern-bar {
      margin-right: -200px;
    }
  }
}

@media (min-width: $media-xl) {
  .bio-hero {
    .bio-hero__image {
      width: $imageWidth - 150px;
      min-width: $imageWidth - 150px;
    }
  }
}

@media (min-width: 1200px) {
  .bio-hero {
    .bio-hero__image {
      margin-left: -40px;
    }

    .pattern-bar {
      margin-right: 0;
    }
  }
}

@media (max-width: $media-xxl-max) {
  .bio-hero {
    padding: 50px 0;

    h1 {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .bio-hero__footer {
      bottom: -50px;
    }

    blockquote.quote {
      font-size: 28px;
    }
  }
}

@media (min-width: $media-xxxl) {
  .bio-hero {
    .bio-hero__image {
      min-height: $imageHeight;
      width: $imageWidth;
      min-width: $imageWidth;
    }

    .caption {
      font-size: $font-size-base;
      letter-spacing: 1.5px;
    }

    blockquote.quote {
      font-size: $font-size-jumbo;
    }
  }
}
