@import "variables";
@import "mixins";

$submenu-column-width: 275px;
$submenu-column-width-xxxl: 330px;
$menu-border-color: #d2d2d4;

//Shared header styles
.header-container {
  position: fixed;
  width: 100%;
  background: white;
  z-index: map-get($z-index, header);
  top: 0;
  box-shadow: $shadow;
}

body.body--has-header {
  padding-top: $header-height;

  @media (max-width: $media-md-max) {
    padding-top: $mobile-header-height;
  }
}

header {
  .submenu__menu {
    margin-left: 0;
    padding-left: 0;
  }

  .submenu__wrapper {
    overflow: hidden;
  }

  .header__logo {
    display: block;
  }

  .accent {
    position: absolute;
  }
}

@media (max-width: $media-xs) {
  body header {
    .header__logo {
      width: 103px;
    }
  }
}

//<mobile header styles
@media (max-width: $media-md-max) {
  body.menu--open {
    overflow: hidden;
  }

  header {
    height: $mobile-header-height;

    &.container .container {
      padding-left: $page-gutter-width-sm;
      padding-right: $page-gutter-width-sm;
    }

    .header__logo {
      width: 113px;
      height: auto;
    }

    .header__nav {
      display: none;
      position: fixed;
      top: $mobile-header-height;
      background-color: $white;
      left: 0;
      right: 0;
      border-top: solid 1px $menu-border-color;
      overflow-y: scroll;
      height: calc(100vh - #{$mobile-header-height});

      &--expanded {
        display: block;
      }
    }

    .header__nav-menu__item {
      border-bottom: solid 1px $menu-border-color;
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &:first-child {
        border-top: solid 1px $menu-border-color;
      }
    }

    .header__nav-menu__link {
      text-transform: uppercase;
      color: $cool-gray;
      display: block;
      padding: $spacing-xs $container-padding-mobile;
      position: relative;
      width: 100%;
      box-sizing: border-box;

      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.05px;
    }

    .header__nav-menu > ul {
      display: block;
      margin: 0;
      padding: 0px;
      width: 100%;
    }

    .header__donate {
      border-top: 30px;
      border-bottom: 0px;

      .header__nav-menu__link {
        border-top: 0px;
        border-bottom: 0px;
        font-size: $font-size-tiny;
        font-weight: $font-weight-semibold;
        padding: 0 $spacing-s;
        margin: 0 $spacing-s;
        color: $white;
        letter-spacing: 1px;
        text-transform: uppercase;
        line-height: $font-size-tiny;
        background-color: $peace;
        width: auto;
        display: flex;
        flex-grow: 1;


        &:hover,
        &:focus-within,
        &:focus {
          text-decoration: none;
          background-color: $peace-l1 !important;
          letter-spacing: 1.2px;
        }
      }
    }

    .submenu__item__toggle {
      position: absolute;
      right: 0;
      top: 0;
      left: 0;
      height: 44px;
      padding: $spacing-tiny;

      img {
        position: absolute;
        right: $container-padding-mobile;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .submenu__wrapper {
    display: flex;
    flex-direction: column;
  }

  .submenu__container {
    padding-top: $spacing-tiny;
    padding-bottom: $spacing-tiny;
  }

  .submenu {
    display: none;
    padding-bottom: $spacing-xs;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.28px;

    &--expanded {
      display: block;
    }

    .submenu__menus {
      margin-top: $spacing-xs;
    }

    .submenu__menu__label {
      margin-top: $spacing-xs;
    }

    .submenu__menu__container {
      flex-grow: 1;
      padding-left: $spacing-s;
    }

    .submenu__header {
      color: $cobalt;
      font-size: $font-size-extra-large;
      font-weight: $font-weight-semibold;
      margin-bottom: $spacing-xxs;
      display: block;
    }

    .submenu__right {
      padding-left: 24px;
      padding-right: 24px;
    }

    .submenu__right--has-mobile-content {
      margin-top: 18px;
    }

    .submenu__menu__label {
      font-size: $font-size-tiny;
      line-height: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-top: 18px;
      margin-bottom: 12px;
      font-weight: $font-weight-semibold;
      white-space: nowrap;
    }

    .submenu__item {
      margin-bottom: 18px;
    }

    .submenu__item__link {
      font-weight: $font-weight-medium;
    }

    .submenu__header__lg {
      font-size: 22px;
      font-weight: bold;
      line-height: 1.45;
      letter-spacing: 0.22px;
    }

    .submenu__image__image {
      margin-bottom: 8px;
      margin-right: 20px;
    }

    .submenu__item__link {
      font-size: 15px;
    }

    .accent {
      display: none;
    }
  }

  .header__search {
    margin: $spacing-s;
    width: 100%;
    border-bottom: $input-border-width solid $input-color;

    form.search {
      display: flex;

      > label {
        display: none;
      }

      > input {
        border-radius: 0;
        border: none;
        flex-grow: 1;
        box-shadow: none;

        &::placeholder {
          text-transform: uppercase;
          font-style: normal;
          font-weight: $font-weight-semibold;
          font-size: $font-size-tiny;
          color: $zia-l1;
        }
      }
    }
  }

  .header__search__form-container {
    flex-grow: 1;

    input {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.2px;
    }
  }

  .header__search__icon {
    align-items: stretch !important;
  }

  .header__search__button {
    padding: 0 $input-padding-x;
    display: flex;
    align-items: center;
  }

  .menu__mobile-content {
    padding: $spacing-s $spacing-l;

    .image {
      margin-bottom: $spacing-tiny;
      width: 100%;
      height: auto;
    }

    .menu__mobile-content__link {
      color: $cobalt;
      font-size: $font-size-extra-large;
      font-weight: $font-weight-semibold;
      margin-bottom: $spacing-tiny;
      display: block;
    }
  }
}

//tablet header styles
@media (min-width: $media-md) and (max-width: $media-md-max) {
  header {
    .menu__mobile-content,
    .header__search__container,
    .header__nav-menu__item {
      padding-left: $container-padding-tablet;
      padding-right: $container-padding-tablet;
    }

    .header__nav-menu__link,
    .header__donate.header__nav-menu__item,
    .submenu__container {
      padding-left: 0px;
      padding-right: 0px;
    }

    .header__search {
      margin-left: 0px;
      margin-right: 0px;
    }

    .submenu__item__toggle {
      img {
        right: $container-padding-tablet;
      }
    }

    .menu__mobile-content {
      width: 100%;
      display: flex;
    }

    .menu__mobile-content__content {
      padding-left: $gutter-width;
    }
  }
}

//desktop header styles
@media (min-width: $media-lg) {
  header {
    height: $header-height;
  }

  .header__logo {
    width: 205px;
  }

  .header__nav {
    height: inherit;
    position: relative;
  }

  .header__nav-menu {
    padding: 0px;
    margin: 0px;
  }

  .header__nav-menu > ul {
    padding: 0px;
    margin: 0px;
  }

  header .header__nav-menu__link {
    cursor: pointer;
    font-size: $font-size-tiny;
    font-weight: $font-weight-semibold;
    letter-spacing: 1.1px;
    color: $dark-gray;
    text-transform: uppercase;
    padding: 0 $spacing-base;
    margin: 0 $spacing-base;
    border-bottom: 10px solid $white;
    border-top: 10px solid $white;
    text-decoration: none;
    line-height: $font-size-small;

    &:hover,
    &:focus-within,
    &:focus {
      text-decoration: none;
    }
  }

  $search-width: 500px;

  .header__search {
    padding: 0 $spacing-m 0 $spacing-xs;

    &--loaded {
      form {
        transition: transform 0.5s;
      }
    }

    .icon {
      cursor: pointer;
    }

    form {
      @include position-fit;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-items: center;
      position: absolute;
      height: $header-height;
      transform: translateX(100%);

      label {
        flex-grow: 0;
        align-content: center;
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        background-color: white;
        font-size: $font-size-small;
        font-weight: $font-weight-semibold;
        letter-spacing: 1.3px;
        color: $dark-gray;
        text-transform: uppercase;
        padding: 0 $spacing-s;
      }

      input {
        transition: background-color 0.5s;
        flex-grow: 1;
        border-radius: 0px;
        border-width: 0px;
        background-color: $warm-gray;
        box-shadow: none;

        &:focus,
        &:hover {
          background-color: $white;
        }
      }
    }
  }

  .header__search__form-container {
    pointer-events: none;

    .header__search__form-container {
      transition: background-color 0.5s;
    }

    position: absolute;
    top: 0;
    right: 87px;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  .header__search--opened {
    .header__search__form-container {
      pointer-events: initial;
      background-color: rgba(255, 255, 255, 0.7);
    }

    form {
      transform: translateX(0);
      right: 0px;
    }
  }

  .header__nav-menu__item:focus,
  .header__nav-menu__item:hover,
  .header__nav-menu__item:focus-within {
    .header__nav-menu__link {
      border-bottom-color: #687ab1;
    }
  }

  .header__nav-menu__item.current {
    .header__nav-menu__link {
      border-bottom-color: $cobalt;
    }
  }

  .header__nav-menu__item.current .header__nav-menu__link {
    color: $cobalt;
  }

  .header__nav__dropdown {
    display: none;
  }

  .header__donate {
    @include transition;
    background-color: $peace;
    z-index: 1;

    &:hover,
    &:focus,
    &:focus-within {
      background-color: $peace-l1 !important;
    }

    .header__nav-menu__link {
      padding: 0 $spacing-xs;
      color: $white;
      border: 0px;
      letter-spacing: 1.6px;
      font-size: $font-size;
    }
  }

  .header__nav-toggle {
    display: none;
  }

  .header-border-bottom {
    border-bottom: 1px solid $luiza-d2;
  }

  .header__nav-menu > ul li {
  }

  .header__nav-menu > ul li a:focus,
  .header__nav-menu > ul li a:focus-within,
  .header__nav-menu > ul li a:hover {
    text-decoration: none;
  }

  header {
    .submenu__wrapper {
      display: none;
      top: $header-height;
      right: 0;
      left: 0;
      position: fixed;
      box-shadow: $shadow;
    }

    .header__nav-menu__item:hover .submenu__wrapper,
    .header__nav-menu__item:focus .submenu__wrapper,
    .header__nav-menu__item:focus-within .submenu__wrapper {
      display: block;
    }

    .submenu {
      background-color: $warm-gray;
      font-size: $font-size-base;
      line-height: 1.56;
      letter-spacing: 0.28px;
      overflow: hidden;
    }

    .submenu__container {
      padding-top: $spacing-l;
      padding-bottom: $spacing-l - 10px;
      background-color: $warm-gray;
      position: relative;
    }

    .submenu__header__lg,
    .submenu__header {
      color: $cobalt;
      font-size: $font-size-large;
      font-weight: $font-weight-semibold;
      margin-bottom: $spacing-tiny;
      display: block;
    }

    a.submenu__header__lg:focus,
    a.submenu__header:focus,
    a.submenu__header__lg:focus-within,
    a.submenu__header:focus-within,
    a.submenu__header__lg:hover,
    a.submenu__header:hover {
      text-decoration: underline !important;
      color: $cobalt-2;
    }

    .submenu__image__image {
      margin-bottom: $spacing-tiny;
      width: 100%;
      height: auto;
    }

    .submenu__right,
    .submenu__left {
      max-width: $submenu-column-width;
    }

    .submenu__menus {
      padding: 0 $gutter-width * 2;
    }

    .submenu__menu__label {
      font-size: $font-size-tiny;
      line-height: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: $spacing-xs;
      font-weight: $font-weight-semibold;
      white-space: nowrap;
    }

    .submenu__item {
      margin-bottom: $spacing-tiny;
    }

    .submenu__item__link {
      font-weight: $font-weight-semibold;
      white-space: nowrap;

      &:hover,
      &:focus-within,
      &:focus {
        text-decoration: underline !important;
        color: $cobalt-2;
      }
    }

    .submenu .accent-extra-large {
      left: -$gutter-width;
      transform: translateX(-100%);
      top: $spacing-xxl;
      max-width: 150px;

      &:focus,
      &:hover,
      &:focus-within {
        text-decoration: underline !important;
        color: $cobalt-2;
      }
    }

    .submenu .accent-jumbo {
      bottom: 0;
      transform: translate(-50%, 55%);
      left: 25%;
    }

    .submenu .accent-large {
      right: $submenu-column-width + $gutter-width;
      top: 40%;
      max-width: 73px;
    }

    .submenu .accent-medium {
      right: -$gutter-width * 2;
      transform: translateX(100%) translateY(-100%);
      top: 40%;
      max-width: 47px;
    }

    .submenu .accent-small {
      right: $submenu-column-width * 1.5;
      top: 80%;
      max-width: 30px;
    }

    .submenu__menu__container {
      padding-right: $gutter-width * 2;
      flex-grow: 1;
    }
  }
}

@media (min-width: $media-xl) {
  header .submenu {
    font-size: $font-size-small;
  }

  header .header__nav-menu__link {
    letter-spacing: 1.3px;
    padding: 0 $spacing-base;
    margin: 0 $spacing-base;
  }
}

@media (min-width: $media-xxl) {
  header .submenu {
    font-size: $font-size-small;
  }

  header .header__nav-menu__link {
    font-size: 13px;
    font-weight: $font-weight-medium;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.3px;
  }
}

@media (min-width: $media-xxxl) {
  header {
    .submenu {
      font-size: $font-size-base;
    }

    .submenu__right,
    .submenu__left {
      max-width: $submenu-column-width-xxxl;
    }

    .submenu__header__lg,
    .submenu__header {
      color: $cobalt;
      font-size: $font-size-extra-large;
    }

    .header__nav-menu__link {
      padding: 0 11px;
      margin: 0 11px;
    }

    .submenu__menus {
      padding: 0 117px;
    }

    .header__nav-menu__link {
      padding: 0 $spacing-xxs;
      margin: 0 $spacing-xxs;
    }

    .header__donate {
      .header__nav-menu__link {
        padding: 0 $spacing-m;
      }
    }

    .submenu .accent-large {
      right: $submenu-column-width-xxxl + $gutter-width;
    }

    .submenu .accent-small {
      right: $submenu-column-width-xxxl * 1.5;
    }
  }
}

@media only screen and (max-width: $media-xxs-max) {
  .header-container > .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
