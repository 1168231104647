@import "../variables";
@import "../mixins";

.pattern-bar {
  background-position: 0;
  height: 18px;
  background-size: auto 100%;

  &--pattern-afghanistan {
    background-size: 44px 44px;
    background-position: 0;
  }

  &--pattern-india {
    background-size: 41px 41px;
    background-position: 0px 1px;
  }

  &--pattern-brazil {
    background-position: 9px 8px;
    background-size: 36px 36px;
  }

  &--pattern-pakistan {
    background-position: -45px -23px;
    background-size: 77px 77px;
  }

  &--pattern-nigeria {
    background-position: 0 0px;
    background-size: 34px 34px;
  }

  &--pattern-syria {
    background-position: -28px;
    background-size: 50px 50px;
  }

  &--white-gray {
    background-position: -20px 0;
    height: 18px;
    background-size: 100px;
  }

  &--pattern-ethiopia {
    background-size: 60px;
  }

  &--pattern-bangladesh {
    background-size: 80px;
    background-position: 0 20%;
  }

  &--pattern-tanzania {
    background-size: 80px;
    background-position: 0 20%;
  }

  &--tall {
    height: 45px;

    &.pattern-bar--pattern-india {
      background-size: 68px 77px;
      background-position: -20px -27px;
    }

    &.pattern-bar--pattern-brazil {
      background-position: 15px 13px;
      background-size: 110px 110px;
    }

    &.pattern-bar--pattern-syria {
      background-position: -58px 48px;
      background-size: 105px 105px;
    }

    &.pattern-bar--pattern-nigeria {
      background-position: 0 0px;
      background-size: 50px 50px;
    }

    &.pattern-bar--pattern-ethiopia {
      background-position: -9px 5px;
      background-size: 105px;
    }

    &.pattern-bar--pattern-bangladesh {
      background-size: 120px;
      background-position: 0 20%;
    }

    &.pattern-bar--pattern-tanzania {
      background-size: 120px;
      background-position: 0 20%;
    }
  }

  &--bar {
    height: 10px;
  }
}
