@import "../variables";
@import "../mixins";

.swiper-outer {
  overflow: hidden;
}

.swiper-container {
  visibility: hidden;
}

.swiper-container.swiper-container-initialized {
  visibility: visible;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: map_get($z-index, carousel);
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 51px;
  width: 51px;
  border-radius: 100%;
  background-color: white;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  &:hover {
    opacity: 0.9;
  }

  &.swiper-button-disabled {
    visibility: hidden;
    cursor: auto;
    pointer-events: none;
  }
}

.swiper-button-prev,
.swiper-button-next {
  &:after {
    display: none;
  }
}

.swiper-button-prev {
  left: -5vw;
  right: auto;
  background-image: url("~images/ui/ui-circle-thin-arrow-left.svg");
}

.swiper-button-next {
  right: -5vw;
  left: auto;
  background-image: url("~images/ui/ui-circle-thin-arrow-right.svg");
}

.container {
  .swiper-container {
    overflow: visible;
  }

  .swiper-button-next {
    margin-right: 0;
  }
}

.swiper-cards {
  overflow: hidden;

  &:not(.swiper-cards--autoheight) {
    .swiper-container {
      .swiper-wrapper {
        display: flex;
        align-items: stretch;

        .swiper-slide {
          display: flex;
          height: unset;
        }
      }
    }
  }

  &--pagination {
    .swiper-wrapper {
      padding-bottom: 56px;
    }
  }
}

.swiper-container {
  .swiper-slide {
    opacity: 0.25;
    pointer-events: none;
    &.swiper-slide-visible {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 5px;
}

.swiper-pagination {
}

@media (max-width: $media-sm-max) {
  .swiper-button-prev {
    left: -40px;
  }

  .swiper-button-next {
    right: -40px;
  }

  .swiper-pagination-bullets .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }

  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 5px;
  }
}
