@import "variables";
@import "mixins";

@each $color, $value in $colors {
  .bg-#{"" + $color} {
    background-color: $value;
  }

  @media (min-width: $media-md) {
    body .bg-md-#{"" + $color} {
      background-color: $value;
    }
  }
}

.bg-transparent {
  background-color: transparent;
}

@media (min-width: $media-md) {
  body .bg-md-transparent {
    background-color: transparent;
  }
}
