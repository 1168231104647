@import "variables";
@import "mixins";

@media (min-width: $media-xxxl) {
  .container {
    max-width: $container-width-xxxl;
    margin: 0 auto;
  }

  .container.container--wide,
  .container--wide .container {
    max-width: $container-width-xxxl - 200px;
  }

  .container.container--narrow,
  .container--narrow .container {
    max-width: $container-width-xxxl - 300px;
  }
}

@media (max-width: $media-xxl-max) {
  .container {
    max-width: $container-width-xxl;
    margin: 0 auto;
  }

  .container.container--wide,
  .container--wide .container {
    max-width: $container-width-xxl - 100px;
  }

  .container.container--narrow,
  .container--narrow .container {
    max-width: $container-width-xxl - 200px;
  }
}

@media (max-width: $media-xl-max) {
  .container {
    max-width: $container-width-xl;
  }

  .container.container--wide,
  .container--wide .container {
    max-width: $container-width-xl - 50px;
  }

  .container.container--narrow,
  .container--narrow .container {
    max-width: $container-width-xl - 100px;
  }
}

@media (max-width: $media-lg-max) {
  .container {
    max-width: $container-width-lg;
  }

  .container.container--wide,
  .container--wide .container {
    max-width: $container-width-lg - 50px;
  }

  .container.container--narrow,
  .container--narrow .container {
    max-width: $container-width-lg - 100px;
  }
}

@media (max-width: $media-md-max) {
  .container {
    max-width: 100%;
    box-sizing: border-box;
    padding-left: $container-padding-tablet;
    padding-right: $container-padding-tablet;
  }

  .container-fluid {
    padding-left: $container-padding-tablet;
    padding-right: $container-padding-tablet;
  }

  .container.container--narrow,
  .container--narrow .container,
  .container.container--wide,
  .container--wide .container {
    max-width: 100%;
  }

  .container .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: $media-sm-max) {
  .container,
  .container-fluid {
    padding-left: 25px;
    padding-right: 25px;
  }
}
