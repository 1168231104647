@import "../variables";
@import "../mixins";

.pattern-hero {
  min-height: 300px;
}

.pattern-hero > div {
  max-width: 600px;
}

.pattern-hero .button {
  background-color: transparent;
  color: white;
  border-color: white;

  &:hover {
    background-color: $white;
    color: $navy !important;
  }
}
