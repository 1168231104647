/****************************
 * Margin Global Helpers
 *****************************
 *
 * Provides the following helper classes:
 *
 * .margin-{direction}-{breakpoint}-{amount}
 * .space-{direction}-{breakpoint}-{amount}
 *
 * Space is similar to margin, except using margin without a direction targets all sides. Using space without a direction targets only margin bottom.
 *
 * Direction: top, right, bottom, left, x (left and right), y (top and bottom)
 * Breakpoint: xl, lg, md, sm, xl (see _variables.scss)
 * Amount: 1 - 9 (see spacer values in _variables.scss)
 *
 * Examples:
 * .margin: margin on all sides
 * .margin-sm: margin on all size targeting only small devices and higher
 * .space: margin at the bottom
 * .margin-bottom-3: A spacer level of 3 margin on the bottom
 * .margin-bottom-xl-3: A spacer level of 3 of margin targeting only extra large devices and higher.
 */

@mixin makeMargin($value, $spacer: null, $breakpoint: null) {
  @if $spacer {
    $spacer: "-#{$spacer}"
  }

  @if $breakpoint {
    $breakpoint: "-#{$breakpoint}"
  }

  .margin#{$breakpoint}#{$spacer} {
    margin: $value !important;
  }

  .margin-bottom#{$breakpoint}#{$spacer},
  .space#{$breakpoint}#{$spacer} {
    margin-bottom: $value !important;
  }
  .margin-left#{$breakpoint}#{$spacer},
  .space-left#{$breakpoint}#{$spacer} {
    margin-left: $value !important;
  }
  .margin-right#{$breakpoint}#{$spacer},
  .space-right#{$breakpoint}#{$spacer} {
    margin-right: $value !important;
  }
  .margin-top#{$breakpoint}#{$spacer},
  .space-top#{$breakpoint}#{$spacer} {
    margin-top: $value !important;
  }
  .margin-x#{$breakpoint}#{$spacer},
  .space-x#{$breakpoint}#{$spacer} {
    margin-left: $value !important;
    margin-right: $value !important;
  }
  .margin-y#{$breakpoint}#{$spacer},
  .space-y#{$breakpoint}#{$spacer} {
    margin-top: $value !important;
    margin-bottom: $value !important;
  }

  .-margin#{$breakpoint}#{$spacer} {
    margin: -$value !important;
  }

  .-margin-bottom#{$breakpoint}#{$spacer} {
    margin-bottom: -$value !important;
  }
  .-margin-left#{$breakpoint}#{$spacer} {
    margin-left: -$value !important;
  }
  .-margin-right#{$breakpoint}#{$spacer} {
    margin-right: -$value !important;
  }
  .-margin-top#{$breakpoint}#{$spacer} {
    margin-top: -$value !important;
  }
  .-margin-x#{$breakpoint}#{$spacer} {
    margin-left: -$value !important;
    margin-right: -$value !important;
  }
  .-margin-y#{$breakpoint}#{$spacer} {
    margin-top: -$value !important;
    margin-bottom: -$value !important;
  }


}

@mixin makeMargins($breakpoint: null) {
  //The default spacer size
  @include makeMargin(map_get($spacers, 7), null, $breakpoint);

  .margin-x#{$breakpoint}-auto {
    margin-left: auto;
    margin-right: auto;
  }

  //Make all the sizes
  @each $spacer, $value in $spacers {
    @include makeMargin($value, $spacer, $breakpoint);
  }
}

@include makeMargins();

//Make the responsive breakpoints
@each $breakpoint, $value in $breakpoints {
  //The default spacer size
  @media (min-width: $value) {
    @include makeMargins($breakpoint);
  }
}

@include set-gutter-attribute('.margin-left-gutter', margin-left);
@include set-gutter-attribute('.margin-top-gutter', margin-top);
@include set-gutter-attribute('.margin-right-gutter', margin-right);
@include set-gutter-attribute('.margin-bottom-gutter', margin-bottom);
@include set-page-gutter-attribute('.margin-left-page-gutter', margin-left);
@include set-page-gutter-attribute('.margin-right-page-gutter', margin-right);
@include set-container-gutter-attribute('.margin-left-container-gutter', margin-left);
@include set-container-gutter-attribute('.margin-right-container-gutter', margin-left);