@import "variables";
@import "mixins";

.cognito .c-forms-form {
  margin-top: 0 !important;
  font-family: $font-family-sans-serif !important;
  background-color: transparent !important;
  max-width: initial !important;
}

.cognito .c-forms-form .c-editor,
.cognito .c-forms-form .c-label {
  font-family: $font-family-sans-serif !important;
  padding-left: 0px !important;
  padding-right: 0px !important;

  // H4 styles.
  color: $zia !important;
  font-weight: bold !important;
}

.cognito .c-forms-form label,
.cognito .c-forms-form .c-label,
.cognito .c-forms-form .c-label label,
.cognito .c-forms-form .c-choice-option label,
.cognito .c-forms-form .c-yesno-radio label {
  text-transform: initial !important;
  letter-spacing: initial !important;
}

.cognito .c-forms-confirmation {
  min-height: initial !important;
}

.cognito .c-forms-form a {
  font-family: $font-family-sans-serif !important;
  color: $peace !important;
}
.cognito .c-forms-form .c-editor.c-span-1 {
  margin-right: 18px !important;
}

.cognito .c-forms-form .c-title {
  padding: 0 !important;
  margin: 12px 0 !important;
}

.cognito .c-forms-form .c-title h3,
.cognito .c-forms-form .c-title h4 {
  font-family: $font-family-sans-serif !important;
  font-size: 17px !important;
  color: $malala !important;
  line-height: 24px !important;
}

.cognito .c-forms-form .c-helptext {
  line-height: 22px !important;
  color: $zia !important;
  font-size: 15px !important;
  letter-spacing: 0.2px !important;
  padding: 0 !important;
}

.cognito .c-forms-form .c-label {
  margin-bottom: 6px;
}

.cognito .c-forms-form .c-editor .c-dropdown:after {
  content: "\f078" !important;
  right: 15px !important;
  font-size: 1.3em !important;
}

.cognito .c-forms-form .c-editor .c-choice-option,
.cognito .c-forms-form .c-editor .c-yesno-radio {
  font-size: 15px !important;
}

.cognito .c-forms-form .c-editor .c-choice-option label {
  margin-bottom: 5px;
}

.cognito
  .c-forms-form
  .c-editor
  input:not([type="checkbox"]):not([type="radio"]),
.cognito .c-forms-form .c-editor select,
.cognito .c-forms-form .c-editor textarea {
  border: 1px solid $malala;
  padding: 13px;
  width: -webkit-fill-available;
  box-shadow: inset 0 0 0 0.2px $malala;
  border-radius: 3px;
}

.cognito .c-forms-form .c-editor input[type="radio"],
.cognito .c-forms-form .c-editor input[type="checkbox"] {
  margin-right: 10px !important;
}

.cognito .c-forms-form .c-editor .c-choice-fillin label {
  display: flex;
  padding-left: 0px;
}

.cognito .c-forms-form .c-editor input:focus,
.cognito .c-forms-form .c-editor select:focus,
.cognito .c-forms-form .c-editor textarea:focus {
  background-color: #fff !important;
}

.cognito .c-forms-form .c-editor.c-html {
  margin-bottom: 15px;
}

.cognito .c-forms-form .c-button {
  display: inline-block !important;
  border: 1px solid $malala !important;
  box-shadow: inset 0 0 0 0.2px $malala !important;
  border-radius: 3px !important;
  padding: 14px 20px !important;
  width: fit-content !important;

  font-family: $font-family-sans-serif !important;
  font-size: 11px !important;
  color: $zia !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
  letter-spacing: 0.4px !important;
  margin: 15px 0 !important;
  background-color: white !important;
}

.cognito .c-forms-form .c-button:hover {
  opacity: 0.8 !important;
  text-decoration: none !important;
}

.cognito .c-forms-form .c-validation {
  display: none !important;
  background-color: transparent !important;
  color: #f34e2e !important;
  margin: 7px 0px !important;
  border-radius: 3px !important;
  font-family: $font-family-sans-serif;
  padding-left: 0px !important;
}

.cognito .c-field {
  padding: 7px 0 !important;
}

.cognito .c-forms-form .c-error .c-validation {
  display: block !important;
}

.cognito .c-fileupload-filelist {
  margin-top: 0px !important;
}

.c-fileupload-dropzone .c-upload-button {
  font-family: $font-family-sans-serif !important;
}

.cognito.c-sml .c-action {
  margin: 0px !important;
}

.cognito .c-forms-form .c-html h1,
.cognito .c-forms-form .c-html h2,
.cognito .c-forms-form .c-html h3,
.cognito .c-forms-form .c-html h4,
.cognito .c-forms-form .c-html h5 {
  font-family: $font-family-sans-serif !important;
}

.cognito .c-forms-confirmation-message {
  padding: 0px !important;
  font-family: $font-family-sans-serif !important;
  line-height: 22px !important;
  color: #3c3e3f !important;
  font-size: 15px !important;
  letter-spacing: 0.2px !important;
}
