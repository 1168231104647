@import "../variables";
@import "../mixins";

.section--assembly-carousel {
  .card {
    border-bottom-color: $malala;
  }

  .card__image__wrapper {
    margin-bottom: $font-size-small;
  }

  .card .pattern-bar {
    margin-top: $spacing-tiny;
  }

  .section__heading {
    font-size: 0;
    text-indent: -9999;
    width: 100%;
    margin: 0 auto 35px auto;
    display: block;
    position: relative;
    z-index: 2;
  }

  .section__heading:after,
  .section__heading:before {
    content: "";
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .section__heading:before {
    background-image: url("~images/assembly-pencil.png");
  }

  .section__heading:after {
    background-image: url("~images/assembly.svg");
  }

  .assembly__excerpt {
    .caption {
      font-size: $font-size-tiny;
      line-height: 1.45;
      letter-spacing: 0.75px;
    }

    .assembly__title {
      line-height: 1.45;
    }
  }

  .card:hover {
    .card__image__wrapper {
      background-color: $yellow;
    }

    .card__image {
      transform: rotate(-3deg);
      transform-origin: right;
    }
  }

  @media (min-width: $media-md) {
    .section__heading {
      max-width: 400px;
      height: 52px;
    }

    .section__heading:before {
      width: 147px;
      height: 216px;
      background-position: center bottom;
      position: absolute;
      bottom: 0;
      right: -36px;
      transform: translate(100%, 0);
    }

    .section__heading:after {
      background-size: 100% 100%;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }

  @media (max-width: $media-sm-max) {
    .assembly {
      overflow-x: hidden;
    }

    .assembly__excerpt .assembly__title {
      font-size: $font-size;
    }

    .section__heading {
      height: unset;
      margin-bottom: 15px;
    }

    .section__heading:before {
      width: 66px;
      height: 97px;
      margin: 0 auto 15px;
    }

    .section__heading:after {
      padding-top: 13%;
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
