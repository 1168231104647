@import "../variables";
@import "../mixins";

.signature--malala {
  svg {
    width: 152px;
    height: 76.89px;
    display: block;

    @media (max-width: $media-md-max) {
      width: 100px;
      height: 51px;
    }

    .underline-mask,
    .signature-mask {
      stroke-dasharray: 2000;
      stroke-dashoffset: 2000;
      stroke: #d8b86d;
    }

    .signature-mask {
      animation: dash 2s linear forwards;
    }

    .underline-mask {
      animation: dash 1s 1.7s linear forwards;
    }
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
