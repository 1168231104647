body .post__card {
  .post-card__heading {
    font-size: 26px;
    font-weight: $font-weight-light;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: $navy;
    margin: 10px 0;
    max-width: 281px;
    font-family: $font-family-sans-serif;
  }

  .card__body__content {
    margin-left: -18px;
  }

  h3.post-card__heading {
  }

  .post__topics {
    margin-top: 5px;
    margin-left: -5px;
  }

  .post-card__link {
    color: inherit;
    text-decoration: none;

    &:active, &:link, &:visited, &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.post__meta {
  display: flex;
  font-size: 11px;
  line-height: 1.64;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  letter-spacing: 1px;
  color: $navy;
  white-space:  nowrap;

  > div:not(:last-child):after {
    display: inline-block;
    margin-left: 8px;
  }
}

.post__author-name {
  font-size: 11px;
  font-weight: $font-weight-black;
  line-height: 1.64;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $cobalt;
}

.post__card--report .card__image {
  box-shadow: 1px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 10px;
}

.post__link:hover {
  text-decoration: none;
}

.post__link:hover h2,
.post__link:hover h3 {
  color: $cobalt;
}


.post__author-name {
  color: $navy;
  margin-top: 20px;
}