@import "../variables";
@import "../mixins";

.blockquote.blockquote--with-quotes .blockquote__quote {
  > p:first-child:before {
    display: inline;
    content: "“";
  }

  > p:last-child:after {
    display: inline;
    content: "”";
  }
}
