/****************************
 * Display Global Helpers
 *****************************
 *
 * Provides the following helper classes:

 * .display-none
 * .display-block
 * .display-flex
 * .display-inline
 * .display-inline-block
 *
 * Use breakpoint specific classes to target screen sizes.
 *
 * .display-{breakpoint}-none
 * .display-{breakpoint}-block
 * .display-{breakpoint}-flex
 * .display-{breakpoint}-inline
 * .display-{breakpoint}-inline-block
 *
 * For example, the following class would apply display: none; to medium devices and higher.
 * .display-md-none
 */
@mixin makeDisplays($breakpoint: null) {
  @if $breakpoint {
    $breakpoint: "-#{$breakpoint}";
  }

  .display#{$breakpoint}-none,
  .hide#{$breakpoint} {
    display: none !important;
  }

  .display#{$breakpoint}-block {
    display: block !important;
  }

  .display#{$breakpoint}-flex {
    display: flex !important;
  }

  .display#{$breakpoint}-inline {
    display: inline !important;
  }

  .display#{$breakpoint}-inline-block {
    display: inline !important;
  }

  .display#{$breakpoint}-grid {
    display: grid !important;
  }
}

@include makeDisplays;

//Make the responsive breakpoints
@each $breakpoint, $value in $breakpoints {
  //The default spacer size
  @media (min-width: $value) {
    @include makeDisplays($breakpoint);
  }
}