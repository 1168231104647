@import "variables";
@import "mixins";

/****************************
 * Grid System
 *****************************
 *
 * Provides a light flex-based grid system.
 *
 * Rows:
 * .row
 * Columns should go inside a row to accommodate for left and right margins on the first and last element.

 * Columns:
 * .column-{$breakpoint}-{width}
 *
 * Breakpoint: xl, lg, md, sm, xl (see _variables.scss)
 * Width: half, quarter, forty, third
 *
 * Examples:
 * .column: Automatically size according to the number of columns
 * .column-half: Size the column at half of it's container.
 * .column-md-third: Size the column at a third of it's container for medium devices and higher.
 */

@mixin makeColumnSizes($breakpoint, $gutter) {
  .column,
  [class^="column-"],
  [class*=" column-"] {
    margin-left: $gutter * 0.5;
    margin-right: $gutter * 0.5;
    box-sizing: border-box;
    width: 100%;
  }

  .column {
    &#{$breakpoint}-full {
      flex-basis: 100%;
    }

    &#{$breakpoint}-half {
      flex-basis: calc(50% - #{$gutter});
    }

    &#{$breakpoint}-quarter {
      flex-basis: calc(25% - #{$gutter});
    }

    &#{$breakpoint}-forty {
      flex-basis: calc(40% - #{$gutter});
    }

    &#{$breakpoint}-sixty {
      flex-basis: calc(60% - #{$gutter});
    }

    &#{$breakpoint}-two-thirds {
      flex-basis: calc(66.66% - #{$gutter});
    }

    &#{$breakpoint}-three-fourths {
      flex-basis: calc(75% - #{$gutter});
    }

    &#{$breakpoint}-third {
      flex-basis: calc(33.33% - #{$gutter});

      &.#{$breakpoint}locked {
        flex-basis: 310px;
      }
    }
  }
}

@mixin makeColumns($breakpoint: null, $gutter-width: 24px) {
  @if $breakpoint {
    $breakpoint: "-#{$breakpoint}";
  }

  @include makeColumnSizes($breakpoint, $gutter-width);

  .gutter-wide > {
    @include makeColumnSizes($breakpoint, $gutter-width * 2);
  }

  @media (min-width: $media-lg) and (max-width: $media-xl-max) {
    $navWidth: 650px;

    .column-brand {
      flex-basis: calc(100% - #{$navWidth} - #{$gutter-width});
    }

    .column-nav {
      flex-basis: calc(#{$navWidth} - #{$gutter-width});
    }
  }

  @media (min-width: $media-xxl) {
    $navWidth: 720px;

    .column-brand {
      flex-basis: calc(100% - #{$navWidth} - #{$gutter-width});
    }

    .column-nav {
      flex-basis: calc(#{$navWidth} - #{$gutter-width});
    }
  }
}

@mixin makeRow($gutter) {
  margin-left: -$gutter * 0.5;
  margin-right: -$gutter * 0.5;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;

  &-wide {
    margin-left: -$gutter * 1.5;
    margin-right: -$gutter * 1.5;

    @include makeColumns(null, $gutter * 1.5);
  }

  @include makeColumns(null, $gutter);

  //Make the responsive breakpoints
  @each $breakpoint, $value in $breakpoints {
    @media (min-width: $value) {
      @include makeColumns($breakpoint, $gutter);
    }
  }
}

.row {
  @include makeRow($gutter-width);
}

.row--wide,
.row.gutter-wide {
  @include makeRow($gutter-width * 2);
}

.row--tight,
.row.gutter-tight {
  @include makeRow($gutter-width * 0.5);
}

.grid {
  display: grid;
  grid-template-columns: var(--grid-template, 1fr 1fr 1fr 1fr);
  grid-template-rows: var(--grid-template-rows, unset);
  grid-column-gap: var(--gutter-width, #{$gutter-width});
  grid-row-gap: var(--gutter-width, #{$gutter-width});
}

.grid--tight {
  --gutter-width: 20px;
}

.grid--wide {
  --gutter-width: 40px;
}

.grid-1 {
  --grid-template: 1fr;
}

.grid-2 {
  --grid-template: 1fr 1fr;
}

.grid-3 {
  --grid-template: 1fr 1fr 1fr;
}

.grid-4 {
  --grid-template: 1fr 1fr 1fr 1fr;
}

@media (max-width: $media-sm-max) {
  .grid {
    --grid-template: 1fr;
  }
}

@media (min-width: $media-md) {
  .grid-md {
    display: grid;
    grid-template-columns: var(--grid-template, 1fr 1fr 1fr 1fr);
    grid-template-rows: var(--grid-template-rows, unset);
    grid-column-gap: var(--gutter-width, #{$gutter-width});
    grid-row-gap: var(--gutter-width, #{$gutter-width});
  }
}
