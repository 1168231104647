@import "../variables";
@import "../mixins";

$modal-spacer: $spacer * 8;

.modal {
  position: relative;
  z-index: map_get($z-index, "modal");
  opacity: 0;
  @include transition();
  pointer-events: none;

  &__overlay {
    @include position-fit;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__scroll {
    overflow-y: scroll;
  }

  &__container {
    @include transition;
    position: fixed !important;
    display: flex;
    flex-direction: column;
    max-height: 80%;
    overflow: scroll;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;

    @media screen and (min-width: $media-lg) and (max-height: 760px) {
      max-height: 95%;
    }
  }

  &.small {
    .modal__container {
      max-width: 600px;
    }
  }

  &.medium {
    .modal__container {
      max-width: 700px;
    }
  }

  &.large {
    .modal__container {
      max-width: 800px;
    }
  }

  @media screen and (min-width: $media-lg) and (max-height: 760px) {
    .modal__container {
      max-width: 500px !important;

      .modal__content {
        margin: 24px 24px 12px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          margin-top: 12px;
          margin-bottom: 12px;
        }
      }

      .modal__buttons {
        margin: 24px;
      }
    }
  }

  &.center {
    .modal__container {
      @include position-center;
    }
  }

  &.top-right {
    .modal__container {
      @include position-top-right($modal-spacer, $modal-spacer * 2);
    }
  }

  &.top-left {
    .modal__container {
      @include position-top-left($modal-spacer, $modal-spacer * 2);
    }
  }

  &.bottom-right {
    .modal__container {
      @include position-bottom-right($modal-spacer * 2, $modal-spacer);
    }
  }

  &.bottom-left {
    .modal__container {
      @include position-bottom-left($modal-spacer * 2, $modal-spacer);
    }
  }

  &.active {
    opacity: 1;
    pointer-events: initial;

    &.top-right {
      .modal__container {
        @include position-top-right($modal-spacer, $modal-spacer);
      }
    }

    &.top-left {
      .modal__container {
        @include position-top-left($modal-spacer, $modal-spacer);
      }
    }

    &.bottom-right {
      .modal__container {
        @include position-bottom-right($modal-spacer, $modal-spacer);
      }
    }

    &.bottom-left {
      .modal__container {
        @include position-bottom-left($modal-spacer, $modal-spacer);
      }
    }
  }

  &__background-image {
    position: absolute;
    object-fit: cover;
    right: 0;
    bottom: 0;
    object-position: right bottom;
  }

  &__pattern-bar {
    height: 50px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.has-pattern-bar {
    .modal__container {
      padding-bottom: 50px;
    }

    .modal__background-image {
      bottom: 50px;
    }
  }

  &__buttons,
  form {
    box-sizing: border-box;
  }

  &__close {
    z-index: 2;
    @include position-top-right(47px, 45px);
    color: $white;
    cursor: pointer;
    display: inline-block;
    padding: $spacer;
  }

  &__content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      padding-right: 25px;
    }
    margin: $modal-spacer $modal-spacer $modal-spacer * 0.5;
    z-index: 1;
    flex-grow: 1;

    &:last-child {
      margin-bottom: $modal-spacer;
    }

    form {
      margin: $modal-spacer * 0.75 0;
    }

    input,
    select {
      box-sizing: border-box;
      width: 100%;
      padding: 15px;
      margin-bottom: 7.5px;
    }

    input[type="submit"] {
      padding-left: 30px;
      padding-right: 30px;
      width: auto;
    }

    li {
      list-style-type: initial;
    }
  }

  &__buttons {
    margin: $modal-spacer * 0.5 $modal-spacer;

    .button {
      margin-right: $spacer;
    }
  }

  @media (max-width: $media-md-max) {
    &__container {
      width: 90%;
      min-width: auto;
    }
  }

  @media (max-width: $media-sm-max) {
    &__close {
      @include position-top-right(7px, 7px);
    }

    &__image {
      object-fit: cover;
    }

    &__content {
      margin-top: $modal-spacer * 0.5;
      margin-right: $modal-spacer * 0.5;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        padding-right: 0;
        margin-bottom: 12px;
      }
    }

    &__content,
    form,
    &__buttons {
      margin-left: $modal-spacer * 0.5;
    }
  }
}
