@import "variables";
@import "mixins";

@import "global/animations";
@import "global/borders";
@import "global/box-sizing";
@import "global/display";
@import "global/flex";
@import "global/font-sizes";
@import "global/font-weight";
@import "global/gutter-cheat";
@import "global/margin";
@import "global/object-fit";
@import "global/padding";
@import "global/positioning";
@import "global/stats";
@import "global/widths";
@import "global/text-alignment";
@import "global/text-colors";
@import "global/visibility";
@import "global/z";
@import "global/highlights";
@import "global/shadow";
@import "global/border-radius";

html,
body {
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  text-decoration-thickness: 1px;
}

body {
  display: block;
}

body.embed {
  padding-top: 0 !important;
}

ul {
  list-style-type: none;
}

p:empty {
  display: none;
}

main ul {
  list-style-type: initial;
}

main {
  flex-grow: 1;
}

section {
  padding: $section-padding 0;
}

#shadow-host-companion {
  padding: 0;
}

picture {
  display: inline;

  img {
    display: inherit;
  }
}

figure {
  margin: 0;
  padding: 0;
}

blockquote {
  padding: 0px;
  font-weight: $font-weight-semibold;
  font-family: $font-family-serif;
  margin: 0;
  line-height: 1.5em;
}

blockquote > i:last-child,
cite {
  text-transform: uppercase;
  letter-spacing: 0.25px;
  font-style: normal;
  font-weight: $font-weight-medium;
  line-height: 1.25;
}

@media (max-width: $media-sm-max) {
  body blockquote > i:last-child,
  body cite {
    text-align: right;
    font-size: $font-size-medium;
    display: block;
  }
}

main ul li p:first-child {
  margin-top: 0px;
}

main ul li p:first-child {
  margin-bottom: 0px;
}

main ul li {
  margin-bottom: 0.5em;
}

.caption {
  letter-spacing: 0.08em;
  color: $dark-gray;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  margin-bottom: $spacing-base;
  line-height: 18px;
}

.tag {
  color: $dark-gray;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  display: inline;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.image-fluid,
.img-fluid {
  max-width: 100%;
  height: auto;
}

.imgs-fluid {
  img {
    max-width: 100%;
    height: auto;
  }
}

.image-cover,
.img-cover {
  max-width: 100%;
  object-fit: cover;
  object-position: center center;
}

.height-100 {
  height: 100%;
}

.height-auto {
  height: auto;
}

.background-cover {
  background-size: cover;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.pipe {
  font-weight: lighter;
  padding-left: 0.75em;
  padding-right: 0.75em;
}

.reverse {
  transform: rotate(180deg);
}

.letter-spacing-0 {
  letter-spacing: 0;
}

.no-wrap {
  white-space: nowrap;
}

.anchor {
  position: absolute;
  top: -150px;
}

@media (max-width: $media-md-max) {
  blockquote {
    font-size: $font-size-extra-large;
  }

  cite {
    font-size: $font-size-tiny;
  }

  .gutter-cheat {
    margin-left: -30px;
    margin-right: -30px;
  }

  .padding-md {
    padding: 40px;
  }
}

.count-up {
  visibility: hidden;
}

.count-up--started {
  visibility: visible;
}


.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media (max-width: $media-sm-max) {
  .display-sm-flex {
    width: 100%;
  }

  section {
    padding-top: $section-padding-mobile;
    padding-bottom: $section-padding-mobile;
  }
}

@media (max-width: $media-xs-max) {
  .gutter-cheat {
    margin-left: -15px;
    margin-right: -15px;
  }
}

figure figcaption {
  color: $cobalt-3;
  font-style: italic;
  font-size: $font-size-small;
  margin-bottom: 10px;
}
